import React, { useState } from "react"
import { Button, RedBar } from "../.."
import styles from "./styles.module.scss"

function Onboarding({ onContinue }) {
  const [corporate, setCorporate] = useState(null)

  const handleContinue = () => {

    onContinue(corporate)
  }

  return (
    <div className={styles.container}>
      <RedBar>
        <div className={styles.stepLabel}>
          Design Your Gift Cards
        </div>
      </RedBar>
      <div className={styles.content}>
        <div className={styles.title}>
          Before we get started, tell us who you are...
        </div>
        <div className={styles.typeSelector}>
          <Button
            outline
            color={corporate === true ? "yellow" : "red"}
            onClick={() => setCorporate(true)}>
            I'm A Business
          </Button>
          <Button
            outline
            color={corporate === false ? "yellow" : "red"}
            onClick={() => setCorporate(false)}>
            I'm An Individual
          </Button>
        </div>
        <Button className={styles.btnContinue} onClick={handleContinue} disabled={corporate === null}>
          Continue
        </Button>
      </div>

    </div>
  )
}

export default Onboarding
