import React from "react"
import { Button } from "../../.."
import { usePurchaseData } from "../../../../Context/purchase"
import styles from "./styles.module.scss"

function Step3({ onContinue }) {
  const { digitalCardData, setDigitalCardData } = usePurchaseData()
  const { amount } = digitalCardData

  const handleChangeAmount = e => {
    if (e.target.value <= 2000) {
      setDigitalCardData({
        ...digitalCardData,
        amount: e.target.value,
      })
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>What is the amount of your gift card?</div>
      <div className={styles.amountEditor}>
        <input
          className={styles.inputAmount}
          type="number"
          min={1}
          max={2000}
          onKeyDown={event => {
            if (event?.key === "-" || event?.key === "+") {
              event.preventDefault()
            }
          }}
          value={amount}
          onChange={handleChangeAmount}
          autoFocus
        />
        <div className={styles.lblAmount}>
          Feel free to add-in <br /> any amount you like
        </div>
      </div>
      {!!amount && (
        <div className={styles.amountResult}>
          ${(+amount).toFixed(2)}
          <div className={styles.lblResult}>Your generosity is so awesome!</div>
        </div>
      )}
      <Button
        className={styles.btnContinue}
        disabled={!(Number(amount) >= 1)}
        onClick={onContinue}>
        Continue
      </Button>
    </div>
  )
}

export default Step3
