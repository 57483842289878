import classnames from "classnames"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Button, Carousel, CarouselItem, CarouselIndicators } from "reactstrap"
import Slider1Cards from "../../../images/Slider1Cards.gif"
import Slider2Cards from "../../../images/slide2.png"
import Slider3Cards from "../../../images/slide3.png"
import styles from "./styles.module.scss"

export default function LandingMainCarousel() {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const onExiting = () => {
    setAnimating(true)
  }

  const onExited = () => {
    setAnimating(false)
  }

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === 2 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? 2 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = [
    <CarouselItem
      onExiting={onExiting}
      onExited={onExited}
      key={1}
    >
      <div className={classnames(styles.carousel, styles.carousel1)}>
        <div className={styles.left}>
          <img src={Slider1Cards} alt="Customized gift card graphic (digital and custom), to give-back for Birthdays, Holidays, and Sympathy." />
        </div>
        <div className={styles.right}>
          <h1>Give more meaningful gifts, no matter the occasion...</h1>
          <h2>
            Your clients, friends, and loved ones can redeem for a charity that is close to their hearts
          </h2>
          <a href="/#GiveGiftCards">
            <Button outline color="primary">Shop Cards</Button>
          </a>
        </div>
      </div>
    </CarouselItem>,
    <CarouselItem
      onExiting={onExiting}
      onExited={onExited}
      key={2}>
      <div className={classnames(styles.carousel, styles.carousel2)}>
        <div className={styles.left}>
          <img src={Slider2Cards} alt="A charitable gift that enables the recipient to choose the cause closest to their heart." />
        </div>
        <div className={styles.right}>
          <h1>With more than <br /> <span>2500+</span></h1>
          <h2>Worthwhile causes to choose from, something meaningful for everyone...</h2>
          <a href="/#GiveGiftCards">
            <Button outline color="primary">Shop Cards</Button>
          </a>
        </div>
      </div>
    </CarouselItem>,
    <CarouselItem
      onExiting={onExiting}
      onExited={onExited}
      key={3}>
      <div className={classnames(styles.carousel, styles.carousel3)}>
        <div className={styles.left}>
          <img className="imgSlider3Cards img-fluid" src={Slider3Cards} alt="Charity business gift cards that have a custom, branded redemption page for clients." />
        </div>
        <div className={styles.right}>
          <h1>Create a customized giving experience for your clients or employees</h1>
          <h2>
            Outstanding branding!
            <br />
            Most robust customization suite for charitable giving...
          </h2>
          <Link to="/CorporateSolutions" >
            <Button outline color="light">Corporate Solutions</Button>
          </Link>          
        </div>
      </div>
    </CarouselItem>
  ]

  return (
    <Carousel
      className={styles.container}
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators
        items={slides}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
    </Carousel>
  )
}
