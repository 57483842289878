import React, { useState, useEffect } from "react"
import { UpdateCorpLogo } from "../../../../Code/Data"
import { useUserData } from "../../../../Context/user"
import { usePurchaseData } from "../../../../Context/purchase"
import UploadCustomImage from "../../../Common/UploadCustomImage"
import LoginModal from "../../../Common/LoginModal"
import { Button } from "../../.."
import { toast } from "react-toastify"
import styles from "./styles.module.scss"

function CustomLogo({ onContinue }) {
  const [loading, setLoading] = useState(false)
  const [openLoginModal, setOpenLoginModal] = useState()
  const { loggedInUser, refreshUser, setUser } = useUserData()
  const { digitalCardData, setDigitalCardData } = usePurchaseData()
  const [customImg, setCustomImg] = useState()
  const [brandEcard, setBrandEcard] = useState((!!loggedInUser && !!loggedInUser.CorporateLogoUrl) && digitalCardData.brandEcard !== false)

  useEffect(() => {
    setCustomImg(digitalCardData.customImage)
  }, [])
  const handleUploadImage = async file => {
    setLoading(true)
    const payload = {
      url: file.secure_url,
    }

    const { Succeeded } = await UpdateCorpLogo(payload)
    Succeeded && (await refreshUser())
    if (Succeeded && !!loggedInUser && !!loggedInUser.CorporateLogoUrl) {
      setBrandEcard(true)
      setDigitalCardData(prevData => ({
        ...prevData,
        customImage: customImg,
      }))
      setCustomImg()
    }
    setLoading(false)
  }
  const handleRemoveLogo = async () => {
    setLoading(true)
    const payload = {
      url: null,
    }
    const { Succeeded } = await UpdateCorpLogo(payload)
    Succeeded && (await refreshUser())
    setBrandEcard(false)
    setLoading(false)
  }

  const handleLogoContinue = () => {
    if (loggedInUser) {
      setDigitalCardData({
        ...digitalCardData,
        brandEcard: brandEcard && !!loggedInUser.CorporateLogoUrl,
      })
    }
    onContinue()
  }
  const setUseLogo = () => {
    if (!!loggedInUser && !!loggedInUser.CorporateLogoUrl) {
      setBrandEcard(true)
    } else {
      toast.error((!!loggedInUser && !loggedInUser.CorporateLogoUrl)
        ? "Please upload a corporate logo before selecting to use your corporate logo."
        : "Please log in or create a CharityChoice Corporate account before selecting to use your corporate logo.")
    }
  }

  const handleSuccessLogin = user => {
    setOpenLoginModal(false)
    setUser(user)
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>Would you like to add your own logo?</div>
      {!!loggedInUser ? (
          <>
            {(!loggedInUser.CorporateLogoUrl ? (
              <>
                <UploadCustomImage
                  CardType="CorporateLogos"
                  showGui={false}
                  onChange={handleUploadImage}>
                  <Button className={styles.btnUpload} outline color="yellow">
                    Upload
                    <i className="fa-solid fa-image"></i>
                  </Button>
                </UploadCustomImage>

                <div className={styles.uploadLimitation}>
                  <b>Maximum width</b> - 792 pixels, Maximum size - 10MB
                  <br />
                  <b>Recommended height</b> - less than 460 pixels
                </div>
              </>
            ) : (
              <div className={styles.logoDetail}>
                <div className={styles.fileLabel}>Your current corporate logo</div>
                <img src={loggedInUser.CorporateLogoUrl} alt="Corporate Logo" />
                <div className={styles.actions}>
                  <UploadCustomImage
                    CardType="CorporateLogos"
                    showGui={false}
                    onChange={handleUploadImage}>
                    <div className={styles.btnAction}>Change</div>
                  </UploadCustomImage>
                  <div className={styles.btnAction} onClick={handleRemoveLogo}>
                    Remove
                  </div>
                </div>
              </div>

            ))}</>)
        : <>
          <div className={styles.loginLabel}>If you want to customize your cards with your Corporate Logo, please log in
            or create a new CharityChoice Corporate account.
          </div>
          <Button
            onClick={() => setOpenLoginModal(true)}
            className={styles.btnLogin}>
            Login / Create Account</Button>
        </>
      }
      <div className={styles.typeSelector}>
        <Button
          outline
          color={brandEcard === true ? "yellow" : "red"}
          onClick={setUseLogo}>
          Use my Corporate Logo
        </Button>
        <Button
          outline
          color={brandEcard === false ? "yellow" : "red"}
          onClick={() => setBrandEcard(false)}>
          Do Not use my Corporate Logo
        </Button>
      </div>
      <Button
        className={styles.btnContinue}
        disabled={loading}
        onClick={handleLogoContinue}>
        Let's Continue
      </Button>
      <LoginModal
        isOpen={openLoginModal}
        caption="Log In"
        isCorporate={true}
        onClose={() => setOpenLoginModal(false)}
        onSuccess={handleSuccessLogin}
      />
    </div>
  )
}

export default CustomLogo
