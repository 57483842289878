import React, { useState } from "react"
import Step1 from "./Step1"
import Step2 from "./Step2"
import { parseQuerystring } from "../../../Code/Utilities"



function Steps({ onSuccess, userId }) {
  const [step, setStep] = useState(0)
  const { RC, RCAE, CHARITYID } = parseQuerystring(window.location.search.toUpperCase())
  const STEPS = [Step1, Step2]
  const [codeAsEntered, setCodeAsEntered] = useState(RCAE || RC || '');

  const handleContinue = () => {
    setStep(step + 1)
  }

  const handleBack = () => {
    setStep(step - 1)
  }

  const StepComponent = STEPS[step]

  return <StepComponent
    onContinue={handleContinue}
    onBack={handleBack}
    codeAsEntered={codeAsEntered}
    charityId={CHARITYID}
    userId={userId}
    setCodeAsEntered={setCodeAsEntered}
    onSuccess={onSuccess} />
}

export default Steps
