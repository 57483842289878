import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Label } from "reactstrap";

export default class CustomSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: props.selectedValue,
    };
  }
  handleChange = newValue => {
    this.setState({ selectedValue: newValue.value });
    this.props.onChange(newValue.value);
  };
  render() {
    const { name, placeholder, description, options, className } = this.props;
    const selectedOption =
      this.props.selectedValue !== null && this.props.selectedValue !== ""
        ? options.filter(o => Object.compare(o.value, this.props.selectedValue))
        : null;

    return (
      <>
        <Select
          name={name}
          id={name}
          className={
            className +
            " my-custom-select form-group has-float-label" +
            (description ? "custom-select mb-0" : null)
          }
          classNamePrefix="react-select"
          placeholder={placeholder}
          value={selectedOption}
          onChange={this.handleChange}
          options={options}
          styles={{ zIndex: 4 }}
        />
        <Label
          for={name}
          className="float-label-select"
          hidden={this.props.hideLabel}>
          {placeholder}
        </Label>
        {description}
      </>
    );
  }
}

CustomSelect.propTypes = {
  // selectedValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  hideLabel: PropTypes.bool,
};
CustomSelect.defaultProps = {
  onChange: () => {},
};
