import React, { createContext, useContext, useEffect, useState } from "react"
import {
  GetCharityCategories,
  GetFullCharityList,
  GetGeneralGlobalData,
} from "../Code/Data"

const AppDataContext = createContext()

export const AppDataProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [appData, setAppData] = useState()
  const [fullCharityList, setFullCharityList] = useState([])
  const [charityCategories, setCharityCategories] = useState([])

  useEffect(() => {
    async function getAppData() {
      setLoading(true)
      const { Succeeded, ...data } = await GetGeneralGlobalData()
      Succeeded && setAppData(data)
      const fullCharityList = await GetFullCharityList()
      setFullCharityList(fullCharityList)
      const charityCategories = await GetCharityCategories()
      setCharityCategories(charityCategories)
      setLoading(false)
    }

    getAppData()
  }, [])

  return (
    <AppDataContext.Provider
      value={{
        loading,
        ...appData,
        fullCharityList,
        charityCategories
      }}>
      {children}
    </AppDataContext.Provider>
  )
}

export const useAppData = () => useContext(AppDataContext)
