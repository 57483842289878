import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { useRedemptionData } from "../../Context/redemption";
import Success from "./Success"
import Steps from "./Steps"



function UserRedemptionPage() {
    const { UserId } = useParams();
    const { getRedemptionPageInfo, redemptionPageData } = useRedemptionData();
    const [successData, setSuccessData] = useState();
    

    useEffect(() => {
        if (UserId && redemptionPageData?.UserId !== parseInt(UserId)) {
            getRedemptionPageInfo(parseInt(UserId));
        }
    }, [UserId])

    return successData
        ? <Success successData={successData} userId={parseInt(UserId)} />
        : <Steps onSuccess={(data) => setSuccessData(data)} userId={parseInt(UserId)} />
}
export default UserRedemptionPage;