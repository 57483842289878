import React, { useEffect } from "react"
import { useUserData } from "../../Context/user"
import { AccountTypes } from "../../Code/Data";
import { toast } from "react-toastify"
import LoginModal from "./LoginModal";

export default function RequireLogin({ message, requireCorporate, children }) {
    const { loggedInUser, setUser, loading } = useUserData()
    const handleSuccessLogin = user => { setUser(user); }
    const needsLogin = (!loggedInUser || (loggedInUser.AccountType !== AccountTypes.Corporate && requireCorporate));

    useEffect(() => {
        if (needsLogin && message && !loading) {
            toast.info(message, {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 7000
            });
        }
    }, [message])

    return <>
        {children}
        {needsLogin &&
            <LoginModal
                isOpen={true}
                caption="Log In"
                isCorporate={!!requireCorporate}
                onSuccess={handleSuccessLogin}                
                onGoBack={()=>window.history.go(-1)}
            />}
    </>
}