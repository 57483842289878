import classnames from "classnames"
import React, { useEffect, useState } from "react"
import { Button, Loading } from "../../.."
import {
  GetCustomPhysicalCardTypes,
  GetPhysicalCardTypes,
  ImgCustomCards,
  PhysicalCardTypes,
} from "../../../../Code/Data"
import { parseQuerystring } from "../../../../Code/Utilities"
import { usePurchaseData } from "../../../../Context/purchase"
import styles from "./styles.module.scss"

function Step1({ onContinue }) {
  const [loading, setLoading] = useState(true)
  const [cardTypes, setCardTypes] = useState([])
  const { physicalCardData, setPhysicalCardData } = usePurchaseData()
  const {
    deliveryMethod,
    cardId,
    cardType,
    isMultipleColor,
    denominationAmount,
  } = physicalCardData

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && cardType) {
        onContinue()
      }
    }

    async function getCardTypes() {
      const { index, DI, SHCIDS, card_id, occ_id, OID, oid, Oid, PCT } =
        parseQuerystring(window.location.search)
      const occasionId = OID || oid || Oid;
      const hiddenOccasionIds = SHCIDS;
      const setInitialCardTypeId = PCT;
      
      setLoading(true)
      const res = await (deliveryMethod === PhysicalCardTypes.Custom
        ? GetCustomPhysicalCardTypes()
        : GetPhysicalCardTypes())
      setCardTypes(res)
      setLoading(false)

      if (cardId) {
        if (deliveryMethod === PhysicalCardTypes.Custom) {
          const cardType = res.find(
            ({ CustomPhysicalCardsTypeId }) =>
              CustomPhysicalCardsTypeId === cardId,
          )
          setPhysicalCardData({
            ...physicalCardData,
            cardType,
          })
        } else {
          const cardType = res.find(
            ({ PhysicalCardTypeId }) => PhysicalCardTypeId === cardId,
          )
          const denomination = cardType.Denominations.find(
            ({ Denomination }) => Denomination === denominationAmount,
          )
          setPhysicalCardData({
            ...physicalCardData,
            cardType,
            denomination,
          })
        }
      }
    }

    document.addEventListener("keypress", handleKeyPress)

    getCardTypes()
    return () => {
      document.removeEventListener("keypress", handleKeyPress)
    }
  }, [])

  const handleChangeCardId = cardType => () => {
    setPhysicalCardData({
      ...physicalCardData,
      cardType,
      customImage: null,
    })
  }

  const handleChangeColorType = e => {
    setPhysicalCardData({
      ...physicalCardData,
      isMultipleColor: e.target.checked,
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Select your favorite card for the occasion
      </div>
      <div className={styles.typeSelector}>
        {cardTypes.map((card, index) => (
          <div
            key={index}
            className={classnames(styles.imgCard, {
              [styles.active]:
                deliveryMethod === PhysicalCardTypes.Custom
                  ? card?.CustomPhysicalCardsTypeId ===
                  cardType?.CustomPhysicalCardsTypeId
                  : card?.PhysicalCardTypeId === cardType?.PhysicalCardTypeId,
            })}
            onClick={handleChangeCardId(card)}>
            {deliveryMethod === PhysicalCardTypes.Custom ? (
              <img
                src={ImgCustomCards[card.CustomPhysicalCardsTypeName]}
                alt={card.CustomPhysicalCardsTypeName}
              />
            ) : (
              <img src={card.ImageUrl} alt={card.CardTypeName} />
            )}
          </div>
        ))}
      </div>
      {deliveryMethod === PhysicalCardTypes.Custom && (
        <label className={styles.checkMultiColor}>
          <input
            type="checkbox"
            checked={isMultipleColor}
            onChange={handleChangeColorType}
          />{" "}
          Multi-color Customization
        </label>
      )}
      <Button
        className={styles.btnContinue}
        disabled={!cardType}
        onClick={onContinue}>
        Continue
      </Button>
    </div>
  )
}

export default Step1
