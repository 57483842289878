import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Button } from "../.."
import { DeliveryMethods } from "../../../Code/Data"

function UploadRecipientsInstructionModal({ open, deliveryMethod, onClose }) {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const strDeliveryMethod = Object.keys(DeliveryMethods)[deliveryMethod - 1]

  return (
    <Modal isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        Upload File of Recipients for sending in an Email
      </ModalHeader>
      <ModalBody>
        To upload a spreadsheet file of recipients, follow these steps.
        <ul>
          <li>
            <b>Download the spreadsheet template</b>
            <br />
            <Link
              target="_blank"
              download
              name="downloadTemplate"
              to={`/Account/Orders/FilesForDownload/Charity-Choice_${strDeliveryMethod}_Recipient_File.csv`}>
              Click here
            </Link>{" "}
            to download the spreadsheet template file.
            <br />
            The file is a "csv" formatted text file. Most spreadsheet editors
            such as Microsoft's Excel support editing csv files.
          </li>
          <li>
            <b>Add your recipients to the file</b>
            <br />
            It is strongly advised to use a CSV editor (such as Microsoft Excel)
            to enter your data to assure the correct file format.
          </li>
          <li>
            <b>Save the file</b>
            <br />
            Make sure that the file is saved as a CSV (comma separated) file.
          </li>
          <li>
            <b>Upload your file</b>
            <br />
            Click on the link "Upload a file of recipients" and select the CSV
            file with the data.
          </li>
          <li>
            <b>View, edit or remove recipients</b>
            <br />
            Once the file is uploaded, you will be able to edit any of the
            uploaded recipients information.
            <br />
            You will also be able to remove any recipient by clicking on the "X"
            at the top right of each recipient.
          </li>
          <li>
            <b>Add more recipients</b>
            <br />
            You can upload any number of recipient files. You can also manually
            add more recipients to your list after uploading a file by clicking
            on "+ add another recipient".
            <br />
            NOTE: we do not check for duplicates, if there are two recipients
            with the same information, the system will assume that you wish to
            send two cards.
          </li>
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button size="small" onClick={() => setTooltipOpen(!tooltipOpen)}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UploadRecipientsInstructionModal
