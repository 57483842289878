import $ from "jquery";
import { TryParseJson } from "./Utilities";

export async function get(url) {
  const response = await request(
    "GET",
    null,
    `${ServerURL}/iapi/${url}`
  ).catch(reason => console.error(reason));
  return response;
}

export async function post(data, url) {
  const response = await request(
    "POST",
    data,
    `${ServerURL}/iapi/${url}`
  ).catch(reason => console.error(reason));
  return response;
}

export async function postFile(fileData, url) {
  const fileName = fileData.name,
    formData = new FormData();
  formData.append("fileData", fileData);
  formData.append("fileName", fileName);
  await $.ajax({
    url: `${ServerURL}/iapi/${url}`,
    type: "POST",
    data: formData,
    beforeSend: xhr => {
      xhr.setRequestHeader("cgc-iapi", "AshreiHa'amSheHashemElokuv");
    },
    //The following 3 options tell JQuery not to process data or worry about content-type
    cache: false,
    contentType: false,
    processData: false,
  });
}

async function request(verb, data, url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(verb, url, true);
    xhr.onload = function () {
      const response = TryParseJson(xhr.response);
      if (response.IsBlackListed) {
        window.location.assign("/BlackListed.aspx");
      } else {
        resolve(response);
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.withCredentials = true;
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader('cgc-iapi', 'AshreiHa\'amSheHashemElokuv');
    if (data) {
      xhr.setRequestHeader("Content-Type", "application/json");
      data = JSON.stringify(data);
    }

    xhr.send(data);
  });
}

let ServerURL = '';

/****************************************************************************************************************
 * The following code is to prevent dev api calls being refused due to cross-site restrictions.
 * The local dev server iapi calls have CORS configured when compiled in the debug configuration
 * to allow a few typical dev client configurations through.
 * 
 * The REACT_APP_DEV_BUILD environment variable is only set to 'true' when running dev or start.
 * ***************************************************************************************************************/
if (process.env.REACT_APP_DEV_BUILD === "true") {
  const host = window.location.host.toLowerCase();
  const noBackendServer = (!host.includes("charitygiftcertificates.org") &&
    !host.match(/^(charitychoice|next)\.ngrok\.io$/) &&
    !host.match(/localhost(\:44\d+)?\/?$/));

  /* IF the host is production, dev, localhost, or localhost:44.., or one of our ngrok.io addresses
   * THEN do the request against the current server host (will not be a cross-site ajax call).
   * ELSE request from a known backend server address (a cross-site call, will need current client to match CORS configuration).*/
  if (noBackendServer) {
    const envServer = process.env.REACT_APP_SERVER_URL;
    ServerURL = `https://${envServer || 'localhost:44329'}`;
  }
}

//****************************************************************************************************************** */
