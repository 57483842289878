// import { useEffect } from 'react';
// import { GetRedeemCodeInfo } from "../../../Code/Data"
// import { redeemCodeValidation } from "../../../Code/Utilities"
// import { toast } from "react-toastify"
// import { useUserData } from "../../../Context/user"
//
// export const commonRedemptionEffect = (redeemCode, setCodeValid, setRedeemInfo, setDirectRedemption, setSubmitted) => {
//   // const { loggedInUser } = useUserData();
//
//   useEffect(() => {
//     setCodeValid(!redeemCode)
//     if (!redeemCode) return
//     fetchData()
//
//     const delayDebounceFn = setTimeout(() => {
//       fetchData(redeemCode)
//         .then((res) => {
//           // Handle invalid format
//           if (
//             res?.ErrorMessage &&
//             !redeemCodeValidation(redeemCode)
//           ) {
//             setCodeValid(false)
//             toast.warn(
//               "The Redemption Code entered is not a valid CharityChoice Redemption Code.",
//               {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 7000,
//               },
//             )
//             return
//           }
//
//           // Handle redeemed code
//           else if (res?.RedeemInfo?.HasRedeemed) {
//             setCodeValid(false)
//             toast.warn("The code has already been redeemed.", {
//               position: toast.POSITION.TOP_CENTER,
//               autoClose: 7000,
//             })
//             return
//           }
//           else if (!res?.RedeemInfo.HasRedeemed) {
//             setCodeValid(true)
//             toast.dismiss()
//             handleRedeem(redeemCode, setRedeemInfo, setDirectRedemption, setSubmitted)
//             return
//           }
//         })
//         .catch((error) => {
//           console.error("Error in setTimeout callback:", error)
//         })
//     }, 800)
//
//     return () => clearTimeout(delayDebounceFn)
//   }, [redeemCode]);
//
//   const fetchData = async (redeemCode) => {
//     try {
//       const RedeemInfo = await GetRedeemCodeInfo(redeemCode)
//       return RedeemInfo
//     } catch {
//       return null
//     }
//   }
//
//   const handleRedeem = async (redeemCode, setRedeemInfo, setDirectRedemption, setSubmitted) => {
//     const { Succeeded, ErrorMessage, RedeemInfo } = await GetRedeemCodeInfo(redeemCode)
//     setRedeemInfo(RedeemInfo)
//
//     // Handle direct charity redemption
//     if (RedeemInfo?.HasCharityId) {
//       setDirectRedemption(true)
//       return
//     }
//     setSubmitted(true)
//     if (!redeemCode) {
//       window.location = "/Redeem"
//     }
//
//
//     if (!Succeeded) {
//       toast.error(ErrorMessage, {
//         position: toast.POSITION.TOP_CENTER,
//         autoClose: 7000,
//       })
//       return false
//     }
//     if (!RedeemInfo.HasCustomRedemptionPage) {
//       window.location = `/Redeem/?RC=${redeemCode}`
//     }
//     else if (RedeemInfo.RedemptionPageURL.contains("/pages/CustomRedemptionPages/")) {
//       window.location = RedeemInfo.RedemptionPageURL
//     }
//     else {
//       window.location = `/Redeem/Custom/${loggedInUser.UserId}/?RC=${redeemCode}`
//     }
//   }
// };
import { useEffect } from 'react';
import { useHistory } from "react-router-dom"
import { GetRedeemCodeInfo } from "../../../Code/Data";
import { redeemCodeValidation } from "../../../Code/Utilities";
import { useUserData } from "../../../Context/user";

export const useCommonRedemptionEffect = (redeemCode, setCodeValid, setRedeemInfo, setDirectRedemption, setSubmitted, showWarning) => {
  const history = useHistory()
  const { loggedInUser } = useUserData();

  useEffect(() => {
    setCodeValid(!redeemCode);
    if (!redeemCode) return;

    const delayDebounceFn = setTimeout(() => {
      fetchData(redeemCode)
        .then(({ Succeeded, ErrorMessage, RedeemInfo }) => {
          // Handle invalid format
          if (
            ErrorMessage &&
            !redeemCodeValidation(redeemCode)
          ) {
            setCodeValid(false);
            showWarning("The Redemption Code entered is not a valid CharityChoice Redemption Code.");
            return;
          }
          // Handle redeemed code
          else if (RedeemInfo?.HasRedeemed) {
            setCodeValid(false);
            showWarning("The code has already been redeemed.");
            return;
          }
          else if (!Succeeded && !!ErrorMessage) {
            showWarning(ErrorMessage);
            return false;
          }
          else if (!RedeemInfo.HasRedeemed) {
            setCodeValid(true);
            showWarning(null);
            handleRedeem(RedeemInfo, redeemCode, setRedeemInfo, setDirectRedemption, setSubmitted);
            return;
          }
        })
        .catch((error) => {
          console.error("Error in setTimeout callback:", error);
        });
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [redeemCode, setCodeValid, setRedeemInfo, setDirectRedemption, setSubmitted, loggedInUser]);

  const redirectOldCustomRedemptionPage = (redeemCodeInfo) => {
    if (!!redeemCodeInfo && !!redeemCodeInfo.RedemptionPageURL) {
      if (redeemCodeInfo.HasCustomRedemptionPage) {
        window.location = redeemCodeInfo.RedemptionPageURL
      }
      else if (redeemCodeInfo.RedemptionPageURL.toLowerCase().indexOf("/pages/customredemptionpages/") > -1) {
        window.location = redeemCodeInfo.RedemptionPageURL
      }
    }
  };

  const fetchData = async () => {
    try {
      const result = await GetRedeemCodeInfo(redeemCode);
      return result;
    } catch {
      return null;
    }
  }

  const handleRedeem = async (redeemCodeInfo, redeemCode, setRedeemInfo, setDirectRedemption, setSubmitted) => {
    if (!redeemCodeInfo) {
      const { Succeeded, ErrorMessage, RedeemInfo } = await GetRedeemCodeInfo(redeemCode);
      if (Succeeded) {
        redeemCodeInfo = RedeemInfo;
      }
      else {
        showWarning(ErrorMessage);
        return false;
      }
    }

    if (redeemCodeInfo?.HasCustomRedemptionPage) {
      redirectOldCustomRedemptionPage(redeemCodeInfo);
      return;
    }

    setRedeemInfo(redeemCodeInfo);

    // Handle direct charity redemption
    if (redeemCodeInfo?.HasCharityId) {
      setDirectRedemption(true);
      return;
    }
    setSubmitted(true);
    if (!redeemCode) {
      window.location = "/Redeem";
    }  
    if (!redeemCodeInfo.HasUserRedemptionPage) {
      history.push(`/Redeem/?RC=${redeemCode}`);
    }
    else {
      history.push(`/Redeem/Custom/${loggedInUser?.UserId}/?RC=${redeemCode}`);
    }
  }
};
