import classnames from "classnames"
import React, { useEffect, useState } from "react"
import { srcBgCharityPreselector, srcMagicMobility } from "../../../images"
import { GetCharityInfo, SetPreSelectedCharityIdApi, UpdateCartTipApi } from "../../../Code/Data"
import { useCartData } from "../../../Context/cart"
import CharitySelector from "../../../Components/Common/CharitySelector"
import { Button, Modal } from "../../../Components"
import styles from "./styles.module.scss"

function CharityPreSelector({ onContinue }) {
  const [loading, setLoading] = useState(false)
  const [tipAmount, setTipAmount] = useState()
  const [searchQuery, setSearchQuery] = useState("")
  const [preselectedCharity, setPreselectedCharity] = useState()
  const [openCharitySelectionModal, setOpenCharitySelectionModal] = useState(false)
  const { cart } = useCartData()

  useEffect(() => {
    async function handleCartDetail() {
      setLoading(true)
      const { TotalCostForSKFTip, PreSelectedCharityId } = cart
      setTipAmount(TotalCostForSKFTip)

      if (PreSelectedCharityId > 0) {
        const charity = await GetCharityInfo(PreSelectedCharityId)
        setPreselectedCharity(charity)
      }
      setLoading(false)
    }

    !!cart && handleCartDetail()
  }, [cart])

  const handlePreselectedCharity = async (charity) => {
    setPreselectedCharity(charity)
    setOpenCharitySelectionModal(false)
    setLoading(true)
    await SetPreSelectedCharityIdApi(charity?.CharityId || 0)
    setLoading(false)
  }

  const handleContinue = async () => {
    setLoading(true)
    tipAmount > 0 && await UpdateCartTipApi(tipAmount)
    setLoading(false)
    onContinue()
  }

  return (
    <div className={styles.container}>
      <div className={classnames(styles.tipDonationContent, styles.desktop)}>
        <i className="fa fa-close" />
        <img src={srcMagicMobility} alt="" />
        <div className={styles.donationLabel}>
          Add a tip donation for our cause
        </div>
        <div className={styles.donationDescription}>
          Special Kids Fund is the 501c3 charity that administers this website and provides wheelchair vans to special needs kids, the elderly, adults with debilitating illnesses, and disabled veterans in need. Help us make a difference!
        </div>
        <div className={styles.amountLabel}>
          Enter any dollar amount or leave 0
        </div>
        <div className={styles.amountWrapper}>
          <input
            type="number"
            value={tipAmount}
            onChange={(e) => setTipAmount(Number(e.target.value))}
          />
          ${tipAmount}
        </div>
      </div>
      <div className={classnames(styles.tipDonationContent, styles.mobile)}>
        <div className={styles.amountInput}>
          <img src={srcMagicMobility} alt="" />
          <div className={styles.amountLabel}>
            Enter any dollar amount or leave 0
          </div>
          <div className={styles.amountWrapper}>
            <input
              type="number"
              value={tipAmount}
              onChange={(e) => setTipAmount(Number(e.target.value))}
            />
            ${tipAmount}
          </div>
        </div>
        <div>
          <div className={styles.donationLabel}>
            Add a tip donation for our cause
          </div>
          <div className={styles.donationDescription}>
            Special Kids Fund is the 501c3 charity that administers this website and provides wheelchair vans to special needs kids, the elderly, adults with debilitating illnesses, and disabled veterans in need. Help us make a difference!
          </div>
        </div>
        <i className="fa fa-close" />
      </div>
      <div className={styles.content}>
        <img src={srcBgCharityPreselector} alt="Charity Cards Preview" />
        <div className={styles.title}>
          Would you like to pre-select a charity?
        </div>
        <div className={styles.description}>
          This will be suggested to your gift recipient, as one of their charity choices.
        </div>
        <div className={styles.searchWrapper}>
          <div className={styles.searchInput}>
            <i className="fa fa-search" />
            <input
              value={searchQuery}
              placeholder="Search by name..."
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <Button
            outline
            color="red"
            onClick={() => setOpenCharitySelectionModal(true)}
          >
            Search Our Database
          </Button>
        </div>
        {preselectedCharity && (
          <div className={styles.preselectedCharity}>
            <div className={styles.charityName}>
              You have chosen:{" "}<b>{preselectedCharity.CharityName}</b>
            </div>
            <div className={styles.charityActions}>
              <div className={styles.btnLink}
                onClick={() => setOpenCharitySelectionModal(true)}>
                Change
              </div>
              <div className={styles.btnLink}
                onClick={() => handlePreselectedCharity()}>
                Remove
              </div>
            </div>
          </div>
        )}
        <Button
          className={styles.btnContinue}
          loading={loading}
          onClick={handleContinue}
        >
          {preselectedCharity ? "Continue" : "Skip & Continue"}
        </Button>
      </div>
      <Modal
        isOpen={openCharitySelectionModal}
        onClose={() => setOpenCharitySelectionModal(false)}
      >
        <CharitySelector
          query={searchQuery}
          charities={preselectedCharity ? [preselectedCharity] : []}
          onUpdate={handlePreselectedCharity}
          max={1}
        />
      </Modal>
    </div>
  )
}

export default CharityPreSelector
