import React, { useEffect, useState } from "react"
import { Input } from "reactstrap"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { redeemCodeValidation } from "../../../../Code/Utilities"
import { GetRedeemCodeInfo, RedeemCodes } from "../../../../Code/Data"
import styles from "./styles.module.scss"
import { useUserData } from "../../../../Context/user"
import SuccessRedemption from "../../../RedeemCodes/Success"
import { useAppData } from "../../../../Context/appData"
import { useCommonRedemptionEffect } from "../../Hooks/commonRedemptionEffect"

export default function RedBarMini(props) {
  const history = useHistory()
  const [redeemCode, setRedeemCode] = useState("")
  const [redeemInfo, setRedeemInfo] = useState("")
  const [submitted, setSubmitted] = useState()
  const { style, children } = props
  const { fullCharityList } = useAppData()
  const [codeValid, setCodeValid] = useState()
  const [directRedemption, setDirectRedemption] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [charityName, setCharityName] = useState("")
  const { loggedInUser, logout, setUser } = useUserData()
  const toastId = React.useRef(null);

  const warnToast = message => {
    toast.dismiss(toastId.current || undefined);
    
    if (!!message) {
      toastId.current = toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 7000,
      })
    }
  }

  useCommonRedemptionEffect(redeemCode, setCodeValid, setRedeemInfo, setDirectRedemption, setSubmitted, warnToast)


  useEffect(() => {
    async function handleDirectRedemption() {
      if (directRedemption) {
        await RedeemDirectlyToCharity()
      }
    }
    handleDirectRedemption()
  }, [directRedemption])

  const handleChangeRedeemCode = ({ target }) => {
    setRedeemCode(target.value);
  }

  const RedeemDirectlyToCharity = async () => {
    const selectedCharity = fullCharityList.find(({ CharityId }) => CharityId === redeemInfo.CharityId)
    const payload = {
      RedeemCode: redeemCode,
      Charities: selectedCharity ? [{ CharityId: selectedCharity.CharityId }] : [],
      ShareRedeemerInfo: redeemInfo?.ShowDonor,
    }
    payload.Charities[0].Amount = +redeemInfo.AmountToRedeem
    const { Succeeded, ErrorMessage } = await RedeemCodes(payload)
    if (Succeeded) {
      setShowSuccess(true)
    }
    else {
      warnToast(ErrorMessage)
    }
  }
  const handleRedeem = async () => {
    warnToast(null);

    if (!redeemCode) {
      warnToast(null);
      history.push("/Redeem");
    }

    const { Succeeded, ErrorMessage, RedeemInfo } = await GetRedeemCodeInfo(redeemCode)
    setRedeemInfo(RedeemInfo)

    // Handle direct charity redemption
    if (RedeemInfo?.HasCharityId) {
      setDirectRedemption(true)
      return
    }
    setSubmitted(true)

    if (RedeemInfo?.HasRedeemed) {
      warnToast("Code has already been redeemed.")
      return false
    }
    if (!Succeeded) {
      warnToast(ErrorMessage)
      return false
    }
    if (!RedeemInfo.HasCustomRedemptionPage) {
      warnToast(null);
      history.push(`/Redeem/?RC=${redeemCode}`)
    }
    else if (RedeemInfo.RedemptionPageURL.toLowerCase().indexOf("/pages/customredemptionpages/") > -1) {
      window.location = RedeemInfo.RedemptionPageURL
    }
    else {
      warnToast(null);
      history.push(`/Redeem/Custom/${loggedInUser.UserId}/?RC=${redeemCode}`)
    }
  }

  if (children) {
    return (
      <div className={styles.customContent} style={style}>
        {children}
      </div>
    )
  }
  if (showSuccess) {
    return (
      <SuccessRedemption charityId={redeemInfo.CharityId} />
    )
  }
  return (
    <div className={styles.minimizedContent}>
      <Input
        type="text"
        placeholder="Enter Card Code"
        className={styles.inputRedeemCode}
        onKeyDown={event => event.key === "Enter" && handleRedeem()}
        onChange={(event) => handleChangeRedeemCode(event)}
      />
      <button className={styles.btnRedeemMinimized} onClick={handleRedeem}>
        Redeem Card
      </button>
    </div>
  )
}
