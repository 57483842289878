import React from "react"
import styles from "./styles.module.scss"

export default function Content() {
  return (
    <div className={styles.container}>
      <div className={styles.customRedemptionPageModalTitle}>
        How to prepare & upload your artwork
      </div>
      <div className={styles.customRedemptionPageModalDesc}>
        You can customize the gray area on the card styles shown below. File
        Type: Upload a high-resolution file: <b>ai, eps, psd, pdf</b> or{" "}
        <b>jpg</b> of your image.
        <br />
        <b>Note to Graphic Artists: Please convert text to outlines.</b>
        <br />
        <br />
        You will be contacted by the printer with an online proof for approval.
        (usually within 24 hrs; email is from ashley.brockman@plasticprinters.com;melissa.maul@plasticprinters.com)
        Approval or requests for changes need to be done within their system via
        the proof link in the email. Cards are shipped 5-7 business days from
        approval.
      </div>
    </div>
  )
}
