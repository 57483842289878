import classnames from "classnames"
import React, { useState, useEffect } from "react"
import { useRedemptionData } from "../../../Context/redemption"
import RedBar from "../../Shared/RedBar"
import Step0 from "./Step0"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import styles from "./styles.module.scss"

function Steps({ onBack, onSuccess,setEditMode,isEditMode,hasExisting }) {
  const { redemptionPageData } = useRedemptionData()
  const { hasLogo } = redemptionPageData
  const [step, setStep] = useState(0)
  const STEPS = hasLogo
    ? [Step0, Step1, Step2, Step3, Step4]
    : [Step0, Step2, Step3, Step4]
  const lastStep = step + 1 === STEPS.length
  const handleContinue = () => {
    setStep(step + 1)
  }

  const handleBack = () => {
    step ? setStep(step - 1) : onBack()
  }

  let StepComponent = STEPS[step]

  useEffect(() => {
    StepComponent = STEPS[0]
  }, [isEditMode])

  return (
    <div className={styles.container}>
      <RedBar>
        <div className={styles.stepLabel}>
          Step {step + 1} of {STEPS.length}
        </div>
      </RedBar>
      <div
        className={classnames(styles.content, { [styles.noMargin]: lastStep })}>
        <StepComponent
          onContinue={handleContinue}
          onBack={handleBack}
          onSuccess={onSuccess}
          setEditMode={setEditMode} 
          isEditMode={isEditMode} 
          hasExisting={hasExisting}
        />
        {!lastStep && (
          <button className={styles.btnStepBack} onClick={handleBack}>
            Go Back
            <i className="fa fa-undo" />
          </button>
        )}
      </div>
    </div>
  )
}

export default Steps
