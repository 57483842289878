import React from "react"
import { Container, Row, Col } from "reactstrap"
import RedBar from "./RedBar"

export default function MainPage(props) {
  const className = props.descriptionNotCentered
    ? ""
    : "justify-content-center text-center "
  return (
    <section className={props.className}>
      <RedBar />
      <Container fluid={props.fluid}>
        <Row>
          <Col xs="12" className={`${className}mt-4 mb-2`}>
            <h1>{props.title}</h1>
          </Col>
          <Col
            xs="12"
            md={{ size: 8, offset: 2 }}
            className={`${className} mb-2`}>
            <div className="mb-3">{props.description}</div>
          </Col>
          {props.children}
        </Row>
      </Container>
    </section>
  )
}
