import React, { useState } from "react"
import styles from "./styles.module.scss"

export default function BlurbBanner() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h3>
          Create your own FREE custom redemption page with all purchases.
        </h3>
      </div>
    </div>
  )
}
