import React from "react"
import { useHistory } from "react-router-dom"
import { Row, Col, Button, Card, CardText } from "reactstrap"
import MainPage from "../Components/Shared/MainPage"

export default function WhatIs() {
  const history = useHistory()

  return (
    <MainPage title="What is a Charity Gift Card">
      <div className="px-1 mb-5 px-sm-5 container-fluid">
        <Row>
          <Col md={8}>
            <h3 className="py-4">
              <em>When meaningful matters...</em>
            </h3>
          </Col>
          <Col md={4}>
            <div className="small">
              Listen to a radio station explain how our charity gifts work!
              <audio controls>
                <source
                  src={`${window.location.origin}/images/audio/WELWcharityaircheck121912.mp3`}
                  type="audio/mpeg"
                />
                <source
                  src={`${window.location.origin}/images/audio/WELWcharityaircheck121912.ogg`}
                  type="audio/ogg"
                />
                <embed
                  width="300"
                  autostart="false"
                  type="audio/mpeg"
                  loop={false}
                  height="70"
                  controller="true"
                  src="/images/audio/WELWcharityaircheck121912.mp3"
                />
              </audio>
            </div>
          </Col>
        </Row>
        <p>
          <strong>You make the donation:</strong>
          <br />
          Our charity gifts are actually charity donations.
          <br />
          You decide on the amount you wish to donate. This donation is 100% tax
          deductible for the purchaser,
          <br />
          at the time of the purchase. You will receive an emailed receipt / tax
          acknowledgment for your records.
        </p>
        <p>
          <strong>
            Your gift entitles the recipient to select the charity:
          </strong>
          <br />
          The card-holder (recipient) may designate the funds to the charity
          they choose from our <a href="/Charities.aspx">online list</a>.
          <br />
          Up to three charities may be selected. The recipient enters their
          Redemption Code (found on the card)
          <br />
          to make their selection. We offer a carefully selected list of over
          250 major charitable causes and
          <br />
          hundreds of local charities to choose from, organized into{" "}
          <a href="/Charities/">15 categories</a>.
          <br />
          They cover a broad range, with something meaningful for everyone.
        </p>
        <p>
          <strong>Suggest a charity:</strong>
          <br />
          You may also pre-select one charity that will be suggested to your
          gift-recipient.
          <br />
          <strong>
            This makes a wonderful gift because the gift-recipient
            <br />
            gets to give the funds towards a cause that is{" "}
            <em>'close to their heart'</em>.
            <br />
            Your gift entitles the recipient to select up to three charities.
          </strong>
        </p>
        <p>
          Privacy:
          <br />
          Your privacy is protected, as the charities are not informed who was
          involved in the gift card purchase,
          <br />
          nor the redemption.
          <br />
          We do offer an opt-in for the recipient to share info and comments to
          the charity they have selected.
        </p>{" "}
        <p>
          <Button
            color="primary"
            className="d-md-block mt-3"
            onClick={() => history.push("/#GiveGiftCards")}>
            Purchase A Charity Gift
          </Button>
        </p>
        <p>
          Charity Distribution:
          <br />
          The charities are sent funds quarterly, from designations made to
          them on our website. A modest 5% fundraising,
          <br />
          3% credit card transaction fee plus $.50 per card is deducted from
          the funds prior to allocation.
          <br />
          CharityChoice is a project of Special Kids Fund, a 501c3 nonprofit
          umbrella organization
          <br />
          supporting programs across the country for special needs children. The
          administrative fee helps towards
          <br />
          this important mission for special kids, as well as expenses involved
          in operating this website.
          <br />
          Special Kids Fund, Inc. nonprofit Tax I.D. # 58-2550249.
        </p>
        <p>
          For more information see our <a href="/FAQs.aspx">FAQs</a> and{" "}
          <a href="/FAQs.aspx#Link24">Terms and Conditions</a>.
        </p>
        <Card body className="text-center font-italic">
          <CardText>
            <blockquote>
              We are a the pioneer in recipient-designated charity gifts and are
              making a profound impact on our society. Americans are changing
              how they do their gift-giving; elevating it from mere commercial
              items to more meaningful charitable giving.
            </blockquote>
          </CardText>
        </Card>
      </div>
    </MainPage>
  )
}
