import React from "react"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { usePurchaseData } from "../../../../Context/purchase"
import { Button } from "../../.."
import styles from "./styles.module.scss"
import { PhysicalCardTypes } from "../../../../Code/Data"

function Step2({ onContinue }) {
  const { physicalCardData, setPhysicalCardData } = usePurchaseData()
  const {
    deliveryMethod,
    cardType,
    denomination,
    denominationAmount,
    numOfCards,
  } = physicalCardData

  const handleChangeAmount = denomination => () => {
    setPhysicalCardData({
      ...physicalCardData,
      denomination,
      denominationAmount: denomination.Denomination,
    })
  }

  const handleChangeDenominationAmount = e => {
    setPhysicalCardData({
      ...physicalCardData,
      denominationAmount: Number(e.target.value),
    })
  }

  const handleChangeNumCards = e => {
    setPhysicalCardData({
      ...physicalCardData,
      numOfCards: Number(e.target.value),
    })
  }

  const isInvalid = () => {
    if (deliveryMethod === PhysicalCardTypes.Custom) {
      return !denominationAmount || !numOfCards
    } else {
      return !physicalCardData.denomination || !numOfCards || denomination?.Stock < numOfCards
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>Please add your gift card amount</div>
      {deliveryMethod === PhysicalCardTypes.Custom ? (
        <div className={styles.denominationEditor}>
          <input
            className={styles.inputDenomination}
            type="number"
            value={denominationAmount}
            onChange={handleChangeDenominationAmount}
          />
          {denominationAmount && denominationAmount > 0 &&
            <label className={styles.label} style={{ fontSize: "46px" }}><b>${denominationAmount}</b></label>
          }
        </div>
      ) : (
        <div className="row justify-content-center">
          <UncontrolledDropdown className={styles.denominationSelector}>
            <DropdownToggle nav caret style={denominationAmount ? { fontSize: "36px" } : { fontSize: "20px" }}
                            className={styles.btnToggle}>
              {denominationAmount
                ? `$${denominationAmount}`
                : "Select Value"}
            </DropdownToggle>
            <DropdownMenu className={styles.content}>
              {cardType.Denominations.map((denomination, index) => (
                <DropdownItem
                  key={index}
                  onClick={handleChangeAmount(denomination)}>
                  ${denomination.Denomination}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      )}
      <div className={styles.numberEditor}>
        <label className={styles.label}><b>How Many Cards?</b></label>
        <input
          className={styles.inputNumber}
          type="number"
          max={denomination?.Stock}
          value={numOfCards}
          onChange={handleChangeNumCards}
        />
        {denomination && (denomination.Stock < 100 || denomination.Stock <= numOfCards) && (
          <div className={styles.lblStock}>
            {denomination?.Stock < 1000 ? `Only ${denomination?.Stock} left in stock` : "In stock"}
          </div>
        )}
      </div>
      <Button
        className={styles.btnContinue}
        disabled={isInvalid()}
        onClick={onContinue}>
        Continue
      </Button>
    </div>
  )
}

export default Step2
