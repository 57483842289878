import React, { useEffect, useRef } from "react"
import { parseQuerystring } from "../../Code/Utilities"
import { LandingPageHit } from "../../Code/Data"
import Header from "../../Components/Shared/Header"
import LandingMainCarousel from "../../Components/Landing/LandingMainCarousel"
import LandingMainMini from "../../Components/Landing/LandingMainMini"
import TitleAndMetaTags from "../../Components/Common/TitleAndMetaTags"
import RedBar from "../../Components/Shared/RedBar"
import HelpBanner from "../../Components/Landing/HelpBanner"
import BlurbBanner from "../../Components/Landing/BlurbBanner"
import WaysToGive from "../../Components/Landing/WaysToGive"
import CharityBanner from "../../Components/Landing/CharityBanner"
import CorporateBanner from "../../Components/Landing/CorporateBanner"
import CustomizationBanner from "../../Components/Landing/CustomizationBanner"
import OurClients from "../../Components/Landing/OurClients"
import HistoryBanner from "../../Components/Landing/HistoryBanner"
import LandingPageCarousel from "../../Components/Landing/LandingPageCarousel"
import ReadyToGive from "../../Components/Landing/ReadyToGive"

export default function LandingPage({ anchor }) {
  const innerWidth = useRef(window.innerWidth);
  const showMinimized = innerWidth.current < 1080;

  useEffect(() => {
    LandingPageHit(parseQuerystring(window.location.search));
  }, [])


  return (
    <>
      <TitleAndMetaTags pageName="homepage" />
      <RedBar />
      {showMinimized
        ? <>
          <Header />
          <LandingMainMini />
        </>
        : <>
          <LandingMainCarousel />
          <HelpBanner />
        </>
      }
      <CharityBanner />
      <BlurbBanner />
      <WaysToGive anchor={anchor} />
      <CorporateBanner />
      <CustomizationBanner />
      <OurClients showMini={showMinimized} />
      <HistoryBanner />
      <LandingPageCarousel />
      <ReadyToGive />
    </>
  )
}
