import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Alert } from "reactstrap"
import {
  AccountTypes,
  CheckoutPayPalSDKApi,
  DeliveryMethods,
  PaymentTypes,
  PreCheckoutApi,
  RemoveFromCartApi,
  ShoppingCartItemTypes,
} from "../../../Code/Data"
import { useCartData } from "../../../Context/cart"
import { useUserData } from "../../../Context/user"
import { useAppData } from "../../../Context/appData"
import {
  checkCartNoDirectDonations,
  scrollToElement,
} from "../../../Code/Utilities"
import LoadingSpinner from "../../../Components/Common/LoadingSpinner"
import LoginModal from "../../../Components/Common/LoginModal"
import CorporateCheckModal from "../../../Components/Common/CorporateCheckModal"
import PayPalSDK from "../../../Components/MiscComponents/PayPalSDK"
import { Button } from "../../../Components"
import styles from "./styles.module.scss"
import imgDonateRetailCard from "../../../images/icon.gif"
// import CartReadyAnimation from "../../../Components/Common/CartReadyAnimation"

function Checkout({ onBack, amount }) {
  const history = useHistory()
  const [hasEcards, setHasEcards] = useState(false)
  const [canUseCorporateCheck, setCanUseCorporateCheck] = useState(false)
  const [wouldLikeNotifications, setWouldLikeNotifications] = useState(true)
  const [errMsg, setErrMsg] = useState()
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [openCorporateCheckModal, setOpenCorporateCheckModal] = useState(false)
  const { PayPalJDKClientId } = useAppData()
  const {
    loading: loadingUser,
    loggedInUser,
    logout,
    refreshUser,
  } = useUserData()
  const { loading: loadingCart, cart, refreshShoppingCart } = useCartData()

  useEffect(() => {
    console.log("---logout---", logout)
    // logout()
    // refreshShoppingCart()
  }, [])

  useEffect(() => {
    getEcardReminderEligibility()
    getCorporateCheckEligibility()
  }, [cart, loggedInUser])

  const handleUpdateItem = item => () => {
    const { ShoppingCartItemTypeId } = item
    switch (ShoppingCartItemTypeId) {
      case ShoppingCartItemTypes.DigitalCharityCards:
      case ShoppingCartItemTypes.PrintableDonationCards:
        return history.push(`/Purchase/DigitalCards/?index=${item.ItemIndex}`, { cartItemInfo: item })
      case ShoppingCartItemTypes.PhysicalCard:
        return history.push(`/Purchase/PhysicalCards/?index=${item.ItemIndex}`, { cartItemInfo: item })
      case ShoppingCartItemTypes.CustomPhysicalCard:
        return history.push(`/Purchase/PhysicalCards/?index=${item.ItemIndex}`, { cartItemInfo: item })
      case ShoppingCartItemTypes.HonorCardToEmail:
        return history.push(`/Purchase/HonorCards/?index=${item.ItemIndex}`, { cartItemInfo: item })
      case ShoppingCartItemTypes.HonorCardToPrint:
        return history.push(`/Purchase/HonorCards/?index=${item.ItemIndex}`, { cartItemInfo: item })
      case ShoppingCartItemTypes.DirectToCharityDonation:
        return history.push(
          `/Purchase/DirectToCharityDonation/?index=${item.ItemIndex}`, { cartItemInfo: item }
        )
      case ShoppingCartItemTypes.RedemptionCodePurchase:
        return history.push(
          `/Purchase/RedemptionCodes/?index=${item.ItemIndex}`, { cartItemInfo: item }
        )
      default:
        return
    }
    // if (ShoppingCartItemTypes.DigitalCharityCards === ShoppingCartItemTypeId) {
    //   history.push(`/Purchase/DigitalCards/?index=${item.ItemIndex}`)
    // } else if (ShoppingCartItemTypes.PhysicalCard === ShoppingCartItemTypeId) {
    //   history.push(`/Purchase/PhysicalCards/?index=${item.ItemIndex}`)
    // } else if (
    //   ShoppingCartItemTypes.CustomPhysicalCard === ShoppingCartItemTypeId
    // ) {
    //   history.push(`/Purchase/PhysicalCards/?index=${item.ItemIndex}`)
    // } else if (
    //   ShoppingCartItemTypes.HonorCardToEmail === ShoppingCartItemTypeId
    // ) {
    //   history.push(`/Purchase/HonorCards/?index=${item.ItemIndex}`)
    // }
  }

  const handleRemoveItemFromCart = itemIndex => async () => {
    await RemoveFromCartApi(itemIndex)
    refreshShoppingCart()
  }

  const getEcardReminderEligibility = async () => {
    if (!cart || !cart.Items) {
      return setHasEcards(false)
    }
    const hasEcards = !!(cart.Items.find(
      item =>
        item?.ShoppingCartItemTypeId === ShoppingCartItemTypes.DigitalCharityCards &&
        item?.PurchaseItemInfo.DigitalCardDeliveryType === DeliveryMethods.Email
    ))

    return setHasEcards(hasEcards)
  }

  const getCorporateCheckEligibility = async () => {
    if (!loggedInUser || !cart) {
      return setCanUseCorporateCheck(false)
    }

    return setCanUseCorporateCheck(
      checkCartNoDirectDonations(cart) &&
      cart.GrandTotal >= 500 &&
      [AccountTypes.Corporate, AccountTypes.SpecialEvent].includes(loggedInUser.AccountType)
    )
  }

  const getImageUrl = (shoppingCartItemTypeId, imgUrl) => {
    if (ShoppingCartItemTypes.PhysicalCard === shoppingCartItemTypeId) {
      return `${process.env.PUBLIC_URL}/${imgUrl}`
    } else if (
      ShoppingCartItemTypes.RedemptionCodePurchase === shoppingCartItemTypeId
    ) {
      return imgDonateRetailCard
    } else {
      return imgUrl
    }
  }

  const handleSuccessLogin = user => {
    setOpenLoginModal(false)
    refreshUser()
    // window.location.reload()
  }

  const handleCorporateCheck = () => {
    setOpenCorporateCheckModal(false)
    preCheckOut(PaymentTypes.Corporate)
  }

  const hasFilledOutShippingInfo = () => {
    return (
      cart &&
      cart.Items &&
      cart.Items.reduce(
        (acc, item) => acc && item.HasCompleteShippingInfo === "True",
        true
      )
    )
  }

  const preCheckOut = async selectedCheckoutType => {
    if (!hasFilledOutShippingInfo(cart)) {
      setErrMsg("Please fill out all information before checking out")
      setTimeout(() => scrollToElement(".alert-danger", true, 0), 500)
      return false
    }

    if (!loggedInUser) {
      setOpenLoginModal(true)
      return false
    }

    const precheckOutRequest = {
      IsPayPalExpress: selectedCheckoutType === PaymentTypes.Paypal,
      IsCorporateCheck: selectedCheckoutType === PaymentTypes.Corporate,
      IsPayPalSDKCheckout: selectedCheckoutType === PaymentTypes.PayPalSDK,
      SendEcardReminder: hasEcards && wouldLikeNotifications,
      CategoryForShowOnly: null,
      CategoryForShowAlso: null,
      CustomCharityListId: null,
      CustomCharityList: null,
      PreSelectedCharityId: cart.PreSelectedCharityId,
      ExpressCheckoutUrl: `https://${window.location.host}/Purchase/PayPalCheckout`,
      ExpressCancelUrl: `https://${window.location.host}/Purchase/ShoppingCart`,
    }

    const { Succeeded, RedirectURL, ErrorMessage } = await PreCheckoutApi(
      precheckOutRequest
    )

    if (Succeeded) {
      switch (selectedCheckoutType) {
        case PaymentTypes.Paypal:
          if (RedirectURL) {
            window.location.href = RedirectURL
          }
          break
        case PaymentTypes.CreditCard:
          window.location.href = `https://${window.location.host}/Purchase/CreditCheckout`
          break
        case PaymentTypes.PayPalSDK:
          return true
        case PaymentTypes.Corporate:
          history.push("/Purchase/CorporateCheckout")
          // window.location.href = `https://${window.location.host}/Purchase/CorporateCheckout`
          break
        default:
          break
      }
    } else {
      setErrMsg(ErrorMessage)
      setTimeout(() => scrollToElement(".alert-danger", true, 0), 500)
    }
  }

  const preCheckoutSdk = (data, actions) => {
    return preCheckOut(PaymentTypes.PayPalSDK).then(success =>
      success ? actions.resolve() : actions.reject()
    )
  }

  const payPalSDKOrderSuccess = async details => {
    if (!cart) {
      return setErrMsg("Cart does not exist")
    }

    setErrMsg()

    const { Succeeded, PurchaseId, ErrorMessage } = await CheckoutPayPalSDKApi(details)
    if (Succeeded) {
      const hasRedemptions = cart.Items.reduce(
        (acc, item) => acc || item.Type === "Redemption Codes",
        false
      )
      //Remove paid shopping cart items.
      await refreshShoppingCart()
      history.push({
        pathname: "/Purchase/OrderComplete",
        state: { purchaseId: PurchaseId, hasRedemptions },
      })
    } else {
      const errMsg =
        ErrorMessage ||
        "We are very sorry, an error has occurred. Please try again soon."
      setErrMsg(errMsg)
      setTimeout(() => errMsg && scrollToElement(".alert-danger", true, 0), 500)
    }
  }

  if (loadingCart || loadingUser) {
    return <LoadingSpinner />
  }

  return (
    <div className={styles.container}>
      {!cart?.Items?.length ? (
        <div className={styles.content}>
          <div className={styles.description}>Your shopping cart is empty</div>
        </div>
      ) : (
        <div className={styles.content}>
          {/* <CartReadyAnimation /> */}

          {errMsg && <Alert color="danger">{errMsg}</Alert>}
          <div className={styles.cartDetail}>
            <i className="fa fa-shopping-cart" />${cart.GrandTotal.toFixed(2)}
          </div>
          <div className={styles.description}>You’re Ready to Checkout!</div>
          {!loggedInUser && (
            <Button
              className={styles.btnLogin}
              outline
              color="red"
              onClick={() => setOpenLoginModal(true)}>
              Login & Create Account
            </Button>
          )}
          <div className={styles.checkoutButtons}>
            <PayPalSDK
              clientId={PayPalJDKClientId}
              donationId={
                cart.Items.length > 0 ? cart.Items[0].PaidItemId : null
              }
              showMessages
              showButtons={!!loggedInUser}
              options={{ "enable-funding": "paylater,venmo" }}
              onClick={preCheckoutSdk}
              amount={cart.GrandTotal}
              debug={process.env.REACT_APP_DEV_BUILD === "true"}
              shippingPreference="NO_SHIPPING"
              style={{
                layout: "vertical",
                color: "gold",
                shape: "pill",
                label: "pay",
                tagline: false,
              }}
              onInit={(data, actions) =>
                loggedInUser ? actions.enable() : actions.disable()
              }
              onSuccess={(details, data) => payPalSDKOrderSuccess(details)}
            />
          </div>
          {canUseCorporateCheck && loggedInUser && (
            <Button
              id="btnPayByCorporateCheck"
              className={styles.btnCorporateCheck}
              onClick={() => setOpenCorporateCheckModal(true)}
              color="primary"
              block={true}>
              Pay by Corporate Check
            </Button>
          )}

          <button className={styles.btnStepBack} onClick={onBack}>
            Go Back
            <i className="fa fa-undo" />
          </button>
        </div>
      )}
      <div className={styles.cartContent}>
        <div className={styles.cartContentWrapper}>
          <div className={styles.cartLabel}>Your shopping cart preview</div>
          <div className={styles.cartItems}>
            {cart?.Items.map(item => {
              const {
                ItemIndex,
                ItemImage,
                ShoppingCartItemTypeId,
                ShortDescription,
                SecondaryDescription,
                TotalForCards,
                Quantity,
                Type,
              } = item
              return (
                <div key={ItemIndex}>
                  <div className={styles.cartItem}>
                    <div className={styles.itemImage}>
                      {Quantity > 1 && (
                        <div className={styles.itemQuantity}>{Quantity}</div>
                      )}
                      <img
                        src={getImageUrl(ShoppingCartItemTypeId, ItemImage)}
                        alt={ShortDescription}
                      />
                    </div>
                    <div className={styles.itemDetail}>
                      <div className={styles.itemName}>
                        {ShortDescription || Type}
                      </div>
                      <div className={styles.itemDesc}>
                        {SecondaryDescription}
                      </div>
                    </div>
                    <div className={styles.itemPrice}>
                      ${TotalForCards.toFixed(2)}
                    </div>
                  </div>
                  <div className={styles.actions}>
                    <div
                      className={styles.btnAction}
                      onClick={handleUpdateItem(item)}>
                      <i className="fa fa-pencil mr-1" aria-hidden="true"></i>
                      Change
                    </div>
                    <div
                      className={styles.btnAction}
                      onClick={handleRemoveItemFromCart(ItemIndex)}>
                      <i className="fa fa-trash mr-1" aria-hidden="true"></i>
                      Remove
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          {!!cart.TotalShippingCostForPhysicalcards && (
            <div className={styles.costItem}>
              <div className={styles.costType}>
                Shipping Cost for Physical Cards
              </div>
              <div className={styles.costValue}>
                ${cart.TotalShippingCostForPhysicalcards.toFixed(2)}
              </div>
            </div>
          )}
          {!!cart.TotalShippingCostForCustomPhysicalCards && (
            <div className={styles.costItem}>
              <div className={styles.costType}>
                Shipping Cost for Custom Physical Cards
              </div>
              <div className={styles.costValue}>
                ${cart.TotalShippingCostForCustomPhysicalCards.toFixed(2)}
              </div>
            </div>
          )}
          {!!cart.TotalPrintChargeForCustomPhysicalCards && (
            <div className={styles.costItem}>
              <div className={styles.costType}>
                Printing of Custom Physical Cards
              </div>
              <div className={styles.costValue}>
                ${cart.TotalPrintChargeForCustomPhysicalCards.toFixed(2)}
              </div>
            </div>
          )}
          {!!cart.TotalSetupChargeForCustomPhysicalCards && (
            <div className={styles.costItem}>
              <div className={styles.costType}>
                Set-up of Custom Physical Cards
              </div>
              <div className={styles.costValue}>
                ${cart.TotalSetupChargeForCustomPhysicalCards.toFixed(2)}
              </div>
            </div>
          )}
          {hasEcards && (
            <label className={styles.checkReminder}>
              <input
                type="checkbox"
                checked={wouldLikeNotifications}
                onChange={e => setWouldLikeNotifications(e.target.checked)}
              />{" "}
              A reminder email will be sent to recipients after 6 days, if there
              is no response.
            </label>
          )}
        </div>
      </div>
      <LoginModal
        isOpen={openLoginModal}
        caption="Please Log In to Check Out!"
        onClose={() => setOpenLoginModal(false)}
        onSuccess={handleSuccessLogin}
      />
      <CorporateCheckModal
        open={openCorporateCheckModal}
        onContinue={handleCorporateCheck}
        onClose={() => setOpenCorporateCheckModal(false)}
      />
    </div>
  )
}

export default Checkout
