import React, { useEffect, useState } from "react"
import { Button } from "../../.."
import { DeliveryMethods } from "../../../../Code/Data"
import { parseQuerystring } from "../../../../Code/Utilities"
import { usePurchaseData } from "../../../../Context/purchase"
import styles from "./styles.module.scss"

function Step2({ onContinue }) {
  const { digitalCardData, setDigitalCardData } = usePurchaseData()  
  const [method, setMethod] = useState(digitalCardData.deliveryMethod || null);

  useEffect(() => {
    const { st } = parseQuerystring(window.location.search)
    if (st) {
      setDigitalCardData({
        ...digitalCardData,
        deliveryMethod: st
      })
      onContinue()
    }
  }, [])

  const handleChangeMethod = selectedMethod => () => {
    setMethod(selectedMethod);
  }
  const handleContinue = () => {
    setDigitalCardData({
      ...digitalCardData,
      deliveryMethod: method
    });
    onContinue();
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        How would you like to send your gift card?
      </div>
      <div className={styles.typeMethod}>
        <Button
          outline
          color={method === DeliveryMethods.Text ? "yellow" : "red"}
          onClick={handleChangeMethod(DeliveryMethods.Text)}
        >
          Text Message
        </Button>
        <Button
          outline
          color={method === DeliveryMethods.Email ? "yellow" : "red"}
          onClick={handleChangeMethod(DeliveryMethods.Email)}
        >
          Email
        </Button>
        <Button
          outline
          color={method === DeliveryMethods.Social ? "yellow" : "red"}
          onClick={handleChangeMethod(DeliveryMethods.Social)}
        >
          Messaging Platform
        </Button>
        <Button
          outline
          color={method === DeliveryMethods.Print ? "yellow" : "red"}
          onClick={handleChangeMethod(DeliveryMethods.Print)}
        >
          Print Myself
        </Button>
      </div>
      <Button className={styles.btnContinue} onClick={handleContinue} disabled={method === null}>Continue</Button>
    </div>
  )
}

export default Step2
