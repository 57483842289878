import classnames from "classnames"
import React from "react"
import styles from "./styles.module.scss"

export default function Loading({ className, label }) {
  return (
    <div className={classnames(className, styles.container)}>
      {label && <h1>{label}</h1>}
      <div className={styles.content}>
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.lading} />
      </div>
    </div>
  )
}
