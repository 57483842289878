import classnames from "classnames"
import moment from "moment"
import React, { forwardRef, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { usePurchaseData } from "../../../../Context/purchase"
// import { convertDateToUTC } from "../../../../Code/Utilities"
import { Button } from "../../.."
import styles from "./styles.module.scss"

function getSendDate(sendDate) {
  return !sendDate || sendDate.getTime() > new Date().getTime()
    ? sendDate
    : null;
}

function Step5({ onContinue }) {
  const { digitalCardData, setDigitalCardData } = usePurchaseData()
  const [sendDate, setSendDate] = useState(getSendDate(digitalCardData.sendDate));

  const handleSendNow = () => {
    setSendDate(null);
  }

  const handleChangeDate = date => {
    setSendDate(new Date(date));
  }

  const filterTime = date => {
    const isPastTime = new Date().getTime() > date.getTime()
    return !isPastTime
  }

  const handleContinue = () => {
    setDigitalCardData({
      ...digitalCardData,
      sendDate,
    })
    onContinue();
  }

  const SendLaterButton = forwardRef(({ value, onClick }, ref) => (
    <button
      className={classnames(
        styles.btnSendLater,
        styles[!!sendDate ? "yellow" : "red"]
      )}
      onClick={onClick}
      ref={ref}>
      Send Later
      <i className="fa-solid fa-clock" />
    </button>
  ))

  return (
    <div className={styles.container}>
      <div className={styles.title}>When would you like to send your gift?</div>
      <div className={styles.sendDate}>
        <Button
          outline
          color={sendDate === null ? "yellow" : "red"}
          onClick={handleSendNow}>
          Send Now
        </Button>
        <ReactDatePicker
          showTimeSelect
          selected={sendDate}
          filterTime={filterTime}
          minDate={new Date()}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          onChange={handleChangeDate}
          customInput={<SendLaterButton />}
        />
      </div>
      {sendDate && (
        <div className={styles.strDate}>
          {moment(sendDate).format("MM/DD/YYYY, hh:mm A")}
          {/* (<span>{moment(convertDateToUTC(sendDate)).format("MM/DD/YYYY, hh:mm A")} UTC</span>) */}
        </div>
      )}
      <Button className={styles.btnContinue} onClick={handleContinue} disabled={typeof (sendDate) === 'undefined'}>
        Continue
      </Button>
    </div>
  )
}

export default Step5
