import React from "react"
import { createRoot } from "react-dom/client"
import { Router } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { createBrowserHistory } from "history"
import "react-datepicker/dist/react-datepicker.css"
import "react-multi-carousel/lib/styles.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import App from "./Contents/App"
import { ScrollToTop } from "./Components/Common/ScrollToTop"
import { PurchaseProvider } from "./Context/purchase"
import { CartProvider } from "./Context/cart"
import { UserProvider } from "./Context/user"
import { AppDataProvider } from "./Context/appData"
import { RedemptionDataProvider } from "./Context/redemption"

const history = createBrowserHistory()
const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <Router history={history}>
    <ScrollToTop>
      <AppDataProvider>
        <RedemptionDataProvider>
          <UserProvider>
            <PurchaseProvider>
              <CartProvider>
                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                  limit={1}
                />
                <App />
              </CartProvider>
            </PurchaseProvider>
          </UserProvider>
        </RedemptionDataProvider>
      </AppDataProvider>
    </ScrollToTop>
  </Router>
)
