import React, { useState } from "react"
import {
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import { LoginUserApi, ForgotPasswordApi } from "../../../Code/Data"
import { loginOldAndReturn } from "../../../Code/Utilities"
import styles from "./styles.module.scss"

export default function LoginModal({
  isOpen,
  caption,
  isCorporate,
  onSuccess,
  onClose,
  onGoBack
}) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [message, setMessage] = useState("")

  const handleLogin = async () => {
    setMessage()
    if (!(email && email.length)) {
      return setMessage("Please enter your login email address.")
    }
    if (!(password && password.length)) {
      return setMessage("Please enter your password.")
    }

    const request = { email, password, noPersonal: !!isCorporate }
    const { Succeeded, ErrorMessage, User } = await LoginUserApi(request)
    if (Succeeded) {
      !!onSuccess && onSuccess(User)
    } else {
      setMessage(ErrorMessage)
    }
  }

  const handleForgotPassword = async () => {
    if (email && email.length) {
      setMessage()
    } else {
      return setMessage("Please enter an email.")
    }
    const { Succeeded, ErrorMessage } = await ForgotPasswordApi(email)
    if (Succeeded) {
      setMessage("We've emailed you a link to reset your password")
    } else {
      setMessage(ErrorMessage)
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} className="login-modal">
      <ModalHeader className={styles.modalHeader}>        
        <div>{caption}</div>
        {!!onGoBack && <Button onClick={()=>onGoBack()}>Back</Button>}
      </ModalHeader>
      <ModalBody>
        <Container>
          <FormGroup className="has-float-label">
            <Input
              type="text"
              id="email"
              placeholder=" "
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Label for="email">Email</Label>
          </FormGroup>
          <FormGroup className="has-float-label">
            <Input
              type="password"
              id="password"
              placeholder=" "
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <Label for="password">Password</Label>
          </FormGroup>
          <div className="d-flex justify-content-around align-items-middle flex-wrap">
            <span className="d-flex flex-column justify-content-around align-items-middle">
              <Alert color="danger" isOpen={!!message} toggle={() => setMessage()}>
                <p>{message}</p>
              </Alert>
              <Button
                style={{ fontSize: '12pt' }}
                color="link"
                className="btn btn-link"
                onClick={handleForgotPassword}>
                I forgot my password
              </Button>
            </span>
            <span>
              <Button onClick={handleLogin}>Login</Button>
            </span>
          </div>
        </Container>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-around">
        <Button
          color="primary"
          style={{ fontSize: '12px' }}
          onClick={() =>
            loginOldAndReturn({
              isLogin: false,
              isCorporate,
            })
          }>
          Create an Account
        </Button>
      </ModalFooter>
    </Modal>
  )
}
