import React, { useEffect, useState } from "react"
import Select from "react-select"
import { Button } from "../../.."
import { StatesList } from "../../../../Code/Data"
import { emailValidation } from "../../../../Code/Utilities"
import { usePurchaseData } from "../../../../Context/purchase"
import { useUserData } from "../../../../Context/user"
import LoginModal from "../../../Common/LoginModal"
import styles from "./styles.module.scss"

function Step3({ onContinue }) {
  const [openLoginModal, setOpenLoginModal] = useState()
  const { loggedInUser, refreshUser } = useUserData()
  const { physicalCardData, setPhysicalCardData } = usePurchaseData({})
  const { shippingInfo } = physicalCardData

  useEffect(() => {
    if (loggedInUser) {
      const { FirstName, LastName, Email, Phone } = loggedInUser
      setPhysicalCardData({
        ...physicalCardData,
        shippingInfo: {
          ShippingFirstName: FirstName,
          ShippingLastName: LastName,
          Email,
          ShippingPhone: Phone,
        },
      })
    }
  }, [loggedInUser])

  const handleSuccessLogin = user => {
    setOpenLoginModal(false)
    refreshUser()
  }

  const handleChangeState = e => {
    setPhysicalCardData({
      ...physicalCardData,
      shippingInfo: {
        ...shippingInfo,
        ShippingState: e.value,
      },
    })
  }

  const handleChange = e => {
    setPhysicalCardData({
      ...physicalCardData,
      shippingInfo: {
        ...shippingInfo,
        [e.target.name]: e.target.value,
      },
    })
  }

  const {
    ShippingFirstName,
    ShippingLastName,
    Email,
    ShippingPhone,
    ShippingAddress1,
    ShippingAddress2,
    ShippingCity,
    ShippingZip,
    ShippingState,
  } = shippingInfo || {}

  const validate = () => {
    return (
      !!ShippingFirstName &&
      !!ShippingLastName &&
      !!ShippingAddress1 &&
      !!ShippingCity &&
      !!ShippingState &&
      !!ShippingZip &&
      !!Email &&
      emailValidation(Email)
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>Let’s get your shipping details</div>
      {!loggedInUser && (
        <div
          className={styles.btnLogin}
          onClick={() => setOpenLoginModal(true)}>
          I already have an account
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.row}>
          <input
            name="ShippingFirstName"
            placeholder="First Name"
            value={ShippingFirstName}
            onChange={handleChange}
          />
          <input
            name="ShippingLastName"
            placeholder="Last Name"
            value={ShippingLastName}
            onChange={handleChange}
          />
        </div>
        <div className={styles.row}>
          <input
            name="Email"
            placeholder="Email"
            value={Email}
            onChange={handleChange}
          />
          <input
            name="ShippingPhone"
            placeholder="Phone"
            value={ShippingPhone}
            onChange={handleChange}
          />
        </div>
        <div className={styles.row}>
          <input
            name="ShippingAddress1"
            placeholder="Address Line 1"
            value={ShippingAddress1}
            onChange={handleChange}
          />
        </div>
        <div className={styles.row}>
          <input
            name="ShippingAddress2"
            placeholder="Address Line 2"
            value={ShippingAddress2}
            onChange={handleChange}
          />
        </div>
        <div className={styles.row}>
          <input
            name="ShippingCity"
            placeholder="City"
            value={ShippingCity}
            onChange={handleChange}
          />
          <Select
            name="ShippingState"
            className={styles.stateSelector}
            classNamePrefix="state-select"
            placeholder="State"
            value={StatesList.find(({ value }) => value === ShippingState)}
            onChange={handleChangeState}
            options={StatesList}
            styles={{ zIndex: 4 }}
          />
          <input
            name="ShippingZip"
            placeholder="ZIP"
            value={ShippingZip}
            onChange={handleChange}
          />
        </div>
      </div>
      <Button
        className={styles.btnContinue}
        disabled={!validate()}
        onClick={onContinue}>
        Continue
      </Button>
      <LoginModal
        isOpen={openLoginModal}
        caption="Log In"
        onClose={() => setOpenLoginModal(false)}
        onSuccess={handleSuccessLogin}
      />
    </div>
  )
}

export default Step3
