import React, { Fragment, useEffect, useState } from "react"

import {
  Alert,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Input,
  FormFeedback,
} from "reactstrap"
import AdditionalForm from "./AdditionalForm"
import CustomSelect from "../../Components/Common/CustomSelect"
import { ContactTypeList, TryingTypeList, ContactApi } from "../../Code/Data"
import { parseQuerystring } from "../../Code/Utilities"
import { useUserData } from "../../Context/user"
const qsVariables = parseQuerystring(window.location.search, true)

export default function ContactForm() {
  const [contactInfo, setContactInfo] = useState({
    UserName: "",
    CompanyName: "",
    Email: "",
    Phone: "",
    URL: "",
    ContactTypeId: "",
    Message: "",
    WasTryingTo: "",
    additionalInfo: {},
  })
  const [submitted, setSubmitted] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errMsg, setErrMsg] = useState(null)
  const { loggedInUser } = useUserData()

  useEffect(() => {
    if (loggedInUser) {
      setContactInfo({
        ...contactInfo,
        UserName: `${loggedInUser.FirstName} ${loggedInUser.LastName}`,
        CompanyName: loggedInUser.CompanyName || "",
        Email: loggedInUser.Email || "",
        Phone: loggedInUser.Phone || "",
      })
    }
  }, [loggedInUser])

  const handleChange = ({ target }) =>
    setContactInfo({
      ...contactInfo,
      [target.name]: target.value,
    })

  const handleChangeContactType = ContactTypeId =>
    setContactInfo({
      ...contactInfo,
      ContactTypeId,
    })

  const handleChangeWasTrying = WasTryingTo =>
    setContactInfo({
      ...contactInfo,
      WasTryingTo,
    })

  const onAdditionalInfoChanged = additionalInfo =>
    setContactInfo({
      ...contactInfo,
      additionalInfo,
    })

  const formValidation = () => {
    const { ContactTypeId, URL, Message, UserName, Email } = contactInfo
    if (ContactTypeId === "" || (ContactTypeId === 2 && !URL) || !Message) {
      return false
    }
    if (!loggedInUser && (!UserName || !Email)) {
      return false
    }
    return true
  }

  const submit = async () => {
    const {
      UserName,
      CompanyName,
      Email,
      Phone,
      URL,
      ContactTypeId,
      Message,
      WasTryingTo,
      additionalInfo,
    } = contactInfo
    const {
      purchaseId,
      redemptionCode,
      dateOfGift,
      sourceOfGift,
      giftRecipientName,
      donationAmount,
    } = additionalInfo
    const PurchaseId = WasTryingTo === "Purchase Gift Cards" ? purchaseId : null
    const RedemptionCode =
      WasTryingTo === "Redeem A Gift Card" ? redemptionCode : null
    const DateOfGift =
      WasTryingTo === "Purchase Gift Cards" ||
      WasTryingTo === "Redeem A Gift Card"
        ? dateOfGift
        : null
    const SourceOfGift =
      WasTryingTo === "Redeem A Gift Card" ? sourceOfGift : null
    const GiftRecipientName =
      WasTryingTo === "Purchase Gift Cards" ? giftRecipientName : null
    const DonationAmount =
      WasTryingTo === "Purchase Gift Cards" ||
      WasTryingTo === "Redeem A Gift Card"
        ? donationAmount
        : null
    let PageSource = qsVariables.PAGESOURCE
    if (!PageSource) {
      if (WasTryingTo === "Purchase Gift Cards") {
        PageSource = "Purchase"
      } else if (WasTryingTo === "Redeem A Gift Card") {
        PageSource = "Redeem"
      }
    }
    setSubmitted(true)
    if (formValidation()) {
      const data = {
        ContactTypeId,
        UserName,
        CompanyName,
        Email,
        Phone,
        URL,
        Message,
        WasTryingTo,
        PurchaseId,
        RedemptionCode,
        DateOfGift,
        SourceOfGift,
        GiftRecipientName,
        DonationAmount,
        PageSource,
      }

      setSending(true)
      setErrMsg(null)
      setSuccess(false)
      const { Succeeded, ErrorMessage } = await ContactApi(data)
      setSending(false)
      setErrMsg(ErrorMessage)
      setSuccess(Succeeded)
    }
  }

  const {
    UserName,
    CompanyName,
    Email,
    Phone,
    URL,
    ContactTypeId,
    WasTryingTo,
    Message,
  } = contactInfo
  return (
    <Fragment>
      <h2>
        <u>Contact Form:</u>
      </h2>
      <Row className="mt-3">
        <Col sm={6} className="pr-sm-1">
          <FormGroup className="has-float-label">
            <Input
              name="UserName"
              id="UserName"
              placeholder="Your Name"
              invalid={submitted && !loggedInUser && !UserName}
              value={UserName}
              onChange={handleChange}
            />
            <Label for="UserName">Your Name</Label>
            <FormFeedback>Your Name is required</FormFeedback>
          </FormGroup>
        </Col>
        <Col sm={6} className="pl-sm-1">
          <FormGroup className="has-float-label">
            <Input
              name="CompanyName"
              id="CompanyName"
              placeholder="Company Name"
              value={CompanyName}
              onChange={handleChange}
            />
            <Label for="CompanyName">Company Name</Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="pr-sm-1">
          <FormGroup className="has-float-label">
            <Input
              name="Email"
              id="Email"
              placeholder="Your Email Address"
              invalid={submitted && !loggedInUser && !Email}
              value={Email}
              onChange={handleChange}
            />
            <Label for="Email">Your Email Address</Label>
            <FormFeedback>Your Email Address is required</FormFeedback>
          </FormGroup>
        </Col>
        <Col sm={6} className="pl-sm-1">
          <FormGroup className="has-float-label">
            <Input
              name="Phone"
              id="Phone"
              placeholder="Phone Number"
              value={Phone}
              onChange={handleChange}
            />
            <Label for="Phone">Phone Number</Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="pr-sm-1">
          <FormGroup className="has-float-label">
            <CustomSelect
              className={submitted && ContactTypeId === "" && "invalid"}
              name="ContactTypeId"
              placeholder="Contact Type"
              invalid={submitted && ContactTypeId === ""}
              options={ContactTypeList}
              selectedValue={ContactTypeId}
              onChange={handleChangeContactType}
            />
            <FormFeedback>Contact Type is required</FormFeedback>
          </FormGroup>
        </Col>
        <Col sm={6} className="pl-sm-1">
          <FormGroup className="has-float-label">
            <CustomSelect
              name="WasTryingTo"
              placeholder="Were You Trying To?"
              options={TryingTypeList}
              selectedValue={WasTryingTo}
              onChange={handleChangeWasTrying}
            />
          </FormGroup>
        </Col>
      </Row>
      {ContactTypeId === 2 && (
        <FormGroup className="has-float-label">
          <Input
            name="URL"
            id="URL"
            placeholder="URL"
            invalid={submitted && !URL}
            value={URL}
            onChange={handleChange}
          />
          <Label for="URL">URL</Label>
          <FormFeedback>URL is required</FormFeedback>
        </FormGroup>
      )}
      <FormGroup className="has-float-label">
        <Input
          type="textarea"
          name="Message"
          id="Message"
          placeholder="Your Message"
          invalid={submitted && !Message}
          value={Message}
          onChange={handleChange}
        />
        <Label for="Message">Type mesage</Label>
        <FormFeedback>Message is required</FormFeedback>
      </FormGroup>

      {(WasTryingTo === "Purchase Gift Cards" ||
        WasTryingTo === "Redeem A Gift Card") && (
        <AdditionalForm type={WasTryingTo} onChange={onAdditionalInfoChanged} />
      )}

      <span className="w-100 text-center d-inline-block">
        <Alert
          color="success"
          isOpen={success}
          toggle={() => setSuccess(!success)}>
          Successfully Submitted!
        </Alert>
        <Alert color="danger" isOpen={!!errMsg} toggle={() => setErrMsg(null)}>
          {errMsg}
        </Alert>
        <Button color="primary" onClick={submit}>
          {sending ? "Sending..." : "Submit"}
        </Button>
      </span>
    </Fragment>
  )
}
