import React from "react";

export default function Purchase(props) {
  return (
    <p
      className="btn-link d-md-inline-block text-primary"
      title={props.title}
      onClick={() => props.onClick && props.onClick()}>
      {props.icon && (
        <span className="btn-inner--icon text-primary mr-3">
          <i className={`${props.fab ? "fab" : "fa"} fa-${props.icon}`} />
        </span>
      )}
      {props.title}
    </p>
  );
}
