import React from "react";
import MainPage from "../Components/Shared/MainPage";

export default function NPO() {
  return (
    <MainPage
      title="CharityGiftCertificates.org"
      description={
        <span>
          a project of Special Kids Fund, Inc. promoting charitable giving.
          <br />
          EIN 58-2550249 - www.specialkidsfund.org
        </span>
      }>
      <div className="form-panel px-1 mb-5 px-sm-5 container-fluid">
        <h4 className="pt-3">
          <u>NPO Terms and Conditions to Charitable Organizations</u>
        </h4>
        <p className="small">
          The following is a binding agreement (the "Agreement") between you,
          the charitable organization, (hereinafter, "You") and Special Kids
          Fund, Inc. and/or CharityChoice-Charity Gift Certificates
          (hereinafter, collectively, "CCGC"). By accepting contributions made
          through SKF, You hereby agree to all of the terms and conditions set
          forth in this Agreement. If You do not accept these terms and
          conditions in their entirety, please notify CCGC and we will remove
          your organization from our list.
        </p>

        <ol className="small">
          <li>
            <span className="font-weight-bold font-italic">
              Representations
            </span>
            <ul>
              <li>
                You represent and warrant to CCGC that at all times during the
                term of this Agreement (a) You are recognized by the Internal
                Revenue Service ("IRS") as exempt from federal income tax under
                Internal Revenue Code Section 501(c)(3); (b) You have public
                charity status under Section 509 of the Internal Revenue Code;
                (c) You will utilize all donations received from CCGC in
                accordance with such tax exempt status; and (d) your website
                does not and will not violate any restrictions imposed by
                applicable law on Internal Revenue Code Section 501(c)(3)
                entities. You further represent and warrant that you have
                complied with any and all obligations to register, and that you
                have fully complied with and will bear the costs associated
                with, any and all applicable state and local statutes governing
                the solicitation of charitable gifts, including, without
                limitation, fulfilling any registration requirements thereof.
                You further represent and warrant that you shall promptly notify
                CCGC of any change in your tax exempt status and any inquiry by
                the IRS or any state of local agency regarding the matters set
                forth above.
              </li>
              <li>
                You hereby grant CCGC the express authority to collect donations
                on your behalf. Donations are made directly to SKF, to be
                allocated on a quarterly basis to the chosen charity. You
                further expressly consent to the use by CCGC of your
                organization's name, logo and description as well as any
                publicly available information relating to your organization in
                connection with our efforts to obtain charitable donations on your
                behalf. This may include, but are not necessarily limited to, the use
                of Your name, logo, description and information on the CCGC website; as
                well as in advertising or promotional material, and programs facilitating charitable giving. 
                You further expressly permit CCGC to frame and hyperlink to your website.
              </li>
              <li>
                You recognize and understand that the decision to select
                charitable organizations for possible donations by CCGC donors
                and recipients is at the sole discretion of the Board of
                Directors of CCGC and is subject to change at any time without
                notice, cause or appeal.
              </li>
              <li>
                Should you wish to be removed from the CCGC charity list for any
                reason, please email CCGC at
                RemoveListing@charitygiftcertificates.org.
              </li>
              <li>
                CCGC will pass on all donations, in the form of an allocation
                from SKF, to the designated charity with the deduction of
                $0.50/card and a 8% fundraising costs (comprised of 5% + 3%
                credit card processing - used towards management of the
                fundraising program, with the remainder to benefit special needs
                children) Note: For cards accessed via third parties, not directly on our website, additional fundraising costs may be incurred.
                Donation of merchant gift cards are subject to a
                10% fundraising cost or 5% if submitted via a charity's branded page.
                Allocations are sent in one bulk check to the charities
                following each fiscal quarter, usually within 4-6 weeks. Per IRS
                indication it is not technically the obligation of CCGC to honor
                the designee's request, as all control over the funds is
                relinquished concurrent with the original donation. Only checks
                above $10, for a quarter, will be issued. CCGC does not take
                responsibility for uncashed checks, nor checks sent to the wrong
                address. It is the responsibility of the charity to login to
                their account, to make any changes in the mailing address as
                well as any other changes to their account. Address changes must
                be made prior to the expiration of the old address. Funds from
                uncashed and returned checks will revert to the CCGC general
                fund after 6 months. Only checks above $10, for a quarter, will
                be issued.
              </li>
              <li>
                You hereby agree to indemnify and hold harmless CCGC from and
                against any loss or liability of any kind, including, but not
                limited to, fees and costs incurred in defense thereof, which
                may arise or result from the following, without limitation: the
                material breach or inaccuracy of any covenant, duty,
                representation or warranty set forth above. Your obligation to
                indemnify CCGC shall survive the termination of this Agreement.
              </li>
            </ul>
          </li>
          <li>
            <span className="font-weight-bold font-italic">Liability</span>
            <br />
            Under no circumstances shall CCGC be liable to any other party for
            any direct, indirect, incidental, consequential, special, exemplary
            or punitive damages, including, without limitation, lost profits,
            business interruption, and lost data arising in connection with any
            action arising under this agreement even if a party has been advised
            of the possibility of collecting such damages. If you are
            dissatisfied with any SKF material, or with any of SKF's terms and
            conditions, your sole and exclusive remedy is to request in writing
            that your charity be removed from charities available to CCGC donors
            and recipients. Such requests can be emailed to the following
            address RemoveListing@charitygiftcertificates.org.
          </li>
          <li>
            <span className="font-weight-bold font-italic">
              Disclaimer of Warranties
            </span>
            <br />
            To the fullest extent permissible pursuant to applicable law, CCGC
            makes no and expressly disclaims all warranties, express or implied,
            including, but not limited to, the CCGC giving system and any other
            service, content, tools or related documents or materials (in
            electronic form or otherwise) provided hereunder, including, without
            limitation, any implied warranties of merchantability and fitness
            for a particular purpose or non-infringement and implied warranties
            arising from a course of dealing or course of performance.
          </li>
          <li>
            <span className="font-weight-bold font-italic">
              Indemnification
            </span>
            <br />
            You agree to defend, indemnify and hold harmless SKF, its parents,
            subsidiaries and affiliates and its respective officers, directors,
            agents, distributors, franchisees and employees against any loss,
            damage, expense, or cost, including reasonable attorney fees
            (including allocated costs for in-house legal services) arising out
            of any claim, demand, action, suit, investigations, arbitration or
            other proceeding by a third party based on: (1) your material breach
            or inaccuracy of any covenant, duty, representation, or warranty of
            this Agreement; (2) materials contained on your website (including
            any allegation that such materials infringe a third party's
            intellectual property or other proprietary rights). and (3) any
            other aspect of your activities or your website and/or the content
            thereon.
          </li>
          <li>
            <span className="font-weight-bold font-italic">Privacy</span>
            <br />
            CCGC takes the privacy of our donors and gift recipients seriously.
            All personal information relating to donors or gift recipients shall
            remain confidential and all gifts are made anonymously except when
            the donor or gift recipient expressly consents to such disclosure or
            if it is required prior to entering a custom redemption landing
            page.
          </li>
          <li>
            <span className="font-weight-bold font-italic">Binding Nature</span>
            <br />
            Although You and CCGC intend for the foregoing Agreement to be
            binding, You agree to sign the Agreement hereof if requested by SKF.
            By applying and/or accepting contributions made through SKF, You are
            agreeing to all of the terms and conditions contained herein. If you
            do not accept these terms and conditions, please email CCGC at
            RemoveListing@charitygiftcertificates.org and CCGC will remove your
            organization from SKF's network.
          </li>
        </ol>
      </div>
    </MainPage>
  );
}
