import React, { Fragment } from "react";
import MainPage from "../Components/Shared/MainPage";
import { Helmet } from "react-helmet";

export default function DAFPurchases() {
  return (
    <Fragment>
      <Helmet>
        <meta
          name="description"
          content="You can use your Donor-Advised Fund to give our Charity Gift Cards.
          CharityChoice gifts are donations that you make on another's behalf ...with the recipient picking the charity closest to their heart, to benefit.
          Over 2500 charitable causes to pick from. A thoughtful way of giving-back and sharing."
        />
        <title>Give at CharityChoice with Your DAF</title>
      </Helmet>
      <MainPage title="DAF Donor-Advised Funds">
        <div className="px-1 mb-5 px-sm-5 container-fluid">
          <div className="text-center">
            <h4>
              CharityChoice gifts are donations that you make on another's
              behalf.
            </h4>
            <div className="small">
              <strong>
                How to use your DAF for our Charity Gift Cards and Codes.
              </strong>
              <br />
              $500 Minimum
            </div>
            <br />
          </div>
          <p>
            No waiting until the funds are received. Your session will be
            completed when you proceed through checkout and everything will be
            immediately activated.
          </p>
          <h3>Step 1</h3>
          <p>
            Create a Special Account at login.
            <br />
            Select one of these 'Account Types' (not Personal):
            <ul>
              <li>Corporate or Business Account</li>
              <li>Wedding or Bridal Account </li>
              <li>Birthday Party Account</li>
              <li>Events and Celebration Account</li>
            </ul>
            <a href="/Login?CA=1&ReturnPage=^Purchase&AccountType=5">
              Login here
            </a>
          </p>
          <h3>Step 2</h3>
          <p>
            Select the items you want from our{" "}
            <a href="/#GiveGiftCards">'Ways to Give'</a> (for a minimum of $500)
            and click on <strong>'Pay by Corporate Check'</strong> at checkout.
            <br />
            <small>(For Wedding, Birthday and Celebration accounts you can use a personal check)</small>
          </p>
          <h3>Step 3</h3>
          <p>
            <strong>Login to your DAF:</strong>
            <ul>
              <li>
                Enter Tax ID # 58-2550249 (for the nonprofit, Special Kids Fund,
                Inc. who administers this website).
              </li>
              <li>
                Designation will be for 'Where it is needed most' (expedites the
                donation).
              </li>
              <li>
                The Amount should be for the Total on your confirmation email.
              </li>
            </ul>
          </p>
          <p>
            Questions to{" "}
            <a href="mailto:DAF@Charity-Choice.org">DAF@Charity-Choice.org</a>
          </p>
        </div>
      </MainPage>
    </Fragment>
  );
}
