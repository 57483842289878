import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

export default function CorporateCheckModal({ open, onContinue, onClose }) {
  return (
    <Modal
      className="corporate-check-modal"
      isOpen={open}
      toggle={onClose}>
      <ModalHeader>Corporate Check Payment</ModalHeader>
      <ModalBody>
        <p>
          <strong>PLEASE NOTE:</strong> The "pay by corporate check" option
          is only available to corporate or charity group fundraiser
          accounts.
        </p>
        <p>Check payable to:</p>
        <address>
          Charity Gift Certificates
          <br />
          111 Autumn Rd.
          <br />
          Lakewood, NJ 08701
          <br />
        </address>
        <p>
          By clicking on the continue button below, you are confirming that
          you are ordering these cards for a corporate account.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={onContinue}>
          Continue
        </Button>
        <Button
          color="secondary"
          onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
