import React, { useEffect, useState } from "react"
import { Onboarding, Steps } from "../../Components/PhysicalCards"
import { PageHit } from "../../Code/Data"
import { parseQuerystring } from "../../Code/Utilities"

function PhysicalCards() {
  const [gettingStarted, setGettingStarted] = useState()

  useEffect(() => {
    if (window.location.search.length > 3) {
      PageHit(parseQuerystring(window.location.search));
    }
  }, []);

  if (!gettingStarted) {
    return <Onboarding onContinue={setGettingStarted} />
  }

  return <Steps />
}

export default PhysicalCards
