import React, { useEffect, useState } from "react"
import { Label, Input, Alert } from "reactstrap"
import { useParams } from "react-router-dom"
import { useRedemptionData } from "../../../../Context/redemption"
import { srcBgCharityPreselector1 } from "../../../../images"
import { Button } from "../../../"
import { runOnceAfterwards } from "../../../../Code/Utilities"
import UserRedemptionPageHeader from "../../UserRedemptionPageHeader"
import styles from "./styles.module.scss"

function Step1({ onContinue, codeAsEntered, charityId, setCodeAsEntered, userId }) {
  const [agreeTerms, setAgreeTerms] = useState(false)
  const { UserId } = useParams()
  const { redemptionData, setRedemptionData, getRedeemCodeInfo, redemptionPageData,
    redeemCodeInfo, redemptionPageInfo, getRedemptionPageInfo, } = useRedemptionData()
  const { WelcomeMessage, HasRedeemed, BrandEcard, LogoURL } = redeemCodeInfo || {}
  const displayData = (!!redemptionPageInfo
    ? redemptionPageInfo
    : (!!redeemCodeInfo && !!redeemCodeInfo.UserRedemptionPageInfo
      ? redeemCodeInfo.UserRedemptionPageInfo
      : {}));
  const { Caption, HeaderImageURL, MainColor, AccentColor } = displayData;


  useEffect(() => {
    if (redeemCodeInfo && redeemCodeInfo.RedemptionCodeAsEntered !== codeAsEntered) {
      setCodeAsEntered(redeemCodeInfo.RedemptionCodeAsEntered)
    }
  }, [redeemCodeInfo])

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!redeemCodeInfo || codeAsEntered !== redeemCodeInfo.RedemptionCodeAsEntered) {
      getRedeemCodeInfo(codeAsEntered, userId);
    }
  }, [codeAsEntered])

  const handleChangeCode = async () => {
    if (
      !redeemCodeInfo?.RedemptionCodeAsEntered ||
      codeAsEntered.toUpperCase() !== redeemCodeInfo.RedemptionCodeAsEntered.toUpperCase()
    ) {
      await getRedeemCodeInfo(codeAsEntered.toUpperCase(), userId)
      if (UserId) {
        const userId = parseInt(UserId)
        if (userId && redemptionPageData?.UserId !== userId) {
          getRedemptionPageInfo(userId)
        }
      }
    }
  }

  const handleChangeData = e => {
    setRedemptionData({
      ...redemptionData,
      [e.target.name]: e.target.value,
    })
  }

  const dataValidation = () => {
    return agreeTerms && !HasRedeemed && !!codeAsEntered && !!redeemCodeInfo
  }

  const handleContinue = () => {
    setRedemptionData({
      ...redemptionData,
      codeAsEntered,
    })
    onContinue()
  }

  const logoImage =
    BrandEcard && LogoURL ? (
      <div className={styles.title}>
        <img className={styles.imgLogo} src={LogoURL} alt="Corporate Logo" />
      </div>
    ) : null

  return (<>
    <UserRedemptionPageHeader headerImageURL={HeaderImageURL} welcomeMessage={WelcomeMessage} caption={Caption} mainColor={MainColor} />
    <div className={styles.container} style={{ backgroundColor: MainColor }}>
      <img
        className={styles.bgBackground}
        src={srcBgCharityPreselector1}
        alt=""
      />
      <div className={styles.form}>
        {logoImage}
        <div className={styles.title} style={{ color: MainColor }}
          dangerouslySetInnerHTML={{ __html: WelcomeMessage || "Let's redeem your Charity Gift!" }}>
        </div>
        <div className={styles.redemptionDetail}>
          <div className={styles.inutWrapper}>
            <input
              className={styles.inputCode}
              style={{ color: MainColor, borderColor: MainColor }}
              name="code"
              value={codeAsEntered}
              onChange={e => setCodeAsEntered(e.target.value.toUpperCase())}
              onKeyUp={e => runOnceAfterwards(() => { setCodeAsEntered(e.target.value.toUpperCase()); handleChangeCode() }, 2000)}
            />
            <div
              className={styles.linkChangeCode}
              style={{ color: AccentColor }}
              onClick={handleChangeCode}>
             {!!codeAsEntered ? "Change Code" : "Enter Code"}
            </div>
          </div>
          <div className={styles.inputWrapper}>
            <input
              className={styles.inputEmail}
              style={{ color: MainColor, borderColor: MainColor }}
              maxLength={25}
              name="email"
              value={redemptionData?.email || ""}
              placeholder="Email Address"
              onChange={handleChangeData}
            />
          </div>
          <div className={styles.lblEmail} style={{ color: MainColor }}>
            Optional: To receive a confirmation
          </div>
        </div>
        <Label check className={styles.termsWrapper}>
          <Input
            type="checkbox"
            onChange={() => setAgreeTerms(!agreeTerms)}
          />{" "}
          <span className={styles.lblTerms}>
            I affirm that I am the gift recipient or that I received this code
            in a rewards program by me redeeming my points. View our{" "}
            <a href="/FAQs.aspx#Link24">Terms and Conditions.</a>
          </span>
        </Label>
        {HasRedeemed && (
          <Alert color="danger">Code has already been redeemed.</Alert>
        )}
        <div className={styles.btnContinue}>
          <Button
            style={{ backgroundColor: AccentColor, borderColor: AccentColor }}
            disabled={!dataValidation()}
            onClick={handleContinue}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  </>
  )
}

export default Step1
