import React, { useState } from "react"
import { Button, RedBar } from "../.."
import * as Confetti from "react-confetti"
import { useUserData } from "../../../Context/user"
import styles from "./styles.module.scss"

function SuccessCreateUserRedemptionPage({setEditMode}) {
  const { loggedInUser } = useUserData()

  return (
    <>
      <RedBar />
      <Confetti
        colors={["red", "orange"]}
        numberOfPieces={200}
        recycle={false}
      />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>Page Now Live!</div>
          <div className={styles.description}>
            Your redemption page has been
            <br /> customized and ready to use
          </div>
          <div className={styles.methodWrapper}>
            <a href={`/Redeem/Custom/${loggedInUser.UserId}/?RC=TEST123`} target="__blank">
              <Button
                outline
                color="red"
              >
                Preview Page
              </Button>
            </a>

          </div>
          <div className={styles.editContent}>
            <div className={styles.lblEdit}>Need to edit your page?</div>
            <button className={styles.btnStepBack} onClick={() => {
              localStorage.setItem("isEditMode", true);
              setEditMode(true)
              window.location.reload();
            }}>
              Go Back
              <i className="fa fa-undo" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SuccessCreateUserRedemptionPage
