import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse
} from "reactstrap"
import { srcLogo } from "../../../../images"
import styles from "./styles.module.scss"

export default function HeaderFullSize() {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <section className={styles.container}>
      <nav className={styles.content}>
        <Link className={styles.logo} to="/">
          <img
            src={srcLogo}
            alt="CharityChoice Logo"
            longdesc="Tax deductible Donation Gift Cards. The best charity gift a meaningful, thoughtful way of 'giving back'. Choose from causes who help with animal protection and shelters, environment, homeless, education, food banks, children, disease, research and health, disaster relief � floods, tornados, tsunamis, hurricanes, earthquakes."
          />
        </Link>

        <div className={styles.navbarButtons}>
          <div className={styles.btnUser}>
            <i className="fa-regular fa-user" />
            <span className={styles.togglerLabel}>Login</span>
          </div>
          <div
            className={styles.navbarToggler}
            onClick={() => setCollapsed(!collapsed)}>
            <i className="fa fa-bars" />
          </div>
        </div>
        <div className={styles.navLinks}>
          <Link className={styles.navLink} to="/About">
            About
          </Link>
          <UncontrolledDropdown>
            <DropdownToggle nav caret className={styles.navLink}>
              Shop
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <a className="nav-link" href="/Purchase/PhysicalCards">Physical Cards</a>
              </DropdownItem>
              <DropdownItem tag={Link} to="/Purchase/DigitalCards">
                <span className="nav-link">Digital Cards</span>
              </DropdownItem>
              <DropdownItem>
                <Link className="nav-link" id="customPhysicalCardsHelp" to="/Purchase/PhysicalCards?type=3">
                  Custom Gift Cards{"  "}
                  <i className="fa fa-info-circle" />
                </Link>
              </DropdownItem>
              <DropdownItem tag={Link} to="/Purchase/PrintCards">
                <span className="nav-link">Printable Cards</span>
              </DropdownItem>
              <DropdownItem>
                <a className="nav-link" href="/Purchase/HonorCards">Honor Cards</a>
              </DropdownItem>
              <DropdownItem>
                <a className="nav-link" href="/Purchase/RedemptionCodes">Redemption Codes</a>
              </DropdownItem>
              <DropdownItem>
                <a className="nav-link" href="/Purchase/DirectToCharityDonation">Direct Donation</a>
              </DropdownItem>
              <DropdownItem>
                <a className="nav-link" href="/Purchase/DonateYourCard">Donate Retail Cards</a>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <Link className={styles.navLink} to="/CorporateSolutions">
            Corporate Solutions
          </Link>
          <a className={styles.navLink} href="/Charities.aspx">
            Our Charities
          </a>
          <Link className={styles.navLink} to="/Contact">
            Contact Us
          </Link>
        </div>
      </nav>
      <Collapse className={styles.navMenuContent} isOpen={collapsed}>
        <Link className={styles.menuLink} to="/About">
          About
        </Link>
        <div className={styles.menuLink}>Shop</div>
        <div className={styles.subLinks}>
          <a className={styles.menuLink} href="/Purchase/PhysicalCards">
            Physical Cards
          </a>
          <Link className={styles.menuLink} to="/Purchase/DigitalCards">
            Digital Cards
          </Link>
          <Link className={styles.menuLink} to="/Purchase/PhysicalCards?type=3">
            Custom Gift Cards{"  "}
            <i className="fa fa-info-circle" />
          </Link>
          <Link className={styles.menuLink} to="/Purchase/PrintCards">
            Printable Cards
          </Link>
          <Link className={styles.menuLink} to="/Purchase/HonorCards">
            Honor Cards
          </Link>
          <Link className={styles.menuLink} to="/Purchase/RedemptionCodes">
            Redemption Codes
          </Link>
          <Link
            className={styles.menuLink}
            to="/Purchase/DirectToCharityDonation">
            Direct Donation
          </Link>
          <Link className={styles.menuLink} to="/Purchase/DonateYourCard">
            Donate Retail Cards
          </Link>
        </div>
        <Link className={styles.menuLink} to="/CorporateSolutions">
          Corporate Solutions
        </Link>
        <a className={styles.menuLink} href="/Charities.aspx" replace>
          Our Charities
        </a>
        <Link className={styles.menuLink} to="/Contact">
          Contact Us
        </Link>
      </Collapse>
    </section>
  )
}