import React, { useEffect } from "react"
import { GetShoppingCartItem, PhysicalCardTypes } from "../../../Code/Data"
import { Button, RedBar } from "../.."
import styles from "./styles.module.scss"
import { parseQuerystring } from "../../../Code/Utilities"
import { usePurchaseData } from "../../../Context/purchase"

function Onboarding({ onContinue }) {
  const { physicalCardData, setPhysicalCardData } = usePurchaseData()
  const { deliveryMethod } = physicalCardData

  useEffect(() => {
    async function getQueryData() {
      const { index, type } = parseQuerystring(window.location.search)
      if (index) {
        const { Succeeded, Donationinfo } = await GetShoppingCartItem(index)
        if (Succeeded) {
          const {
            PhysicalCardTypeId,
            CustomPhysicalCardTypeId,
            Denomination,
            Quantity,
            ShippingInfo,
            ShipsToRecipient,
            ImageURL,
            IsMultipleColor,
          } = Donationinfo

          const deliveryMethod = CustomPhysicalCardTypeId
            ? PhysicalCardTypes.Custom
            : ShipsToRecipient
              ? PhysicalCardTypes.ToRecipient
              : PhysicalCardTypes.ToMe

          const cardData = {
            index,
            cardId: PhysicalCardTypeId || CustomPhysicalCardTypeId,
            cardType: "",
            deliveryMethod,
            ShippingInfo: ShippingInfo,
            shippingTypeId: ShippingInfo.ShippingTypeId,
            numOfCards: Quantity,
            denominationAmount: Denomination,
            customImage: ImageURL,
            isMultipleColor: IsMultipleColor,
          }
          await setPhysicalCardData(cardData)
          onContinue(true)
        }
      } else if (+type === PhysicalCardTypes.Custom) {
        setPhysicalCardData({
          ...physicalCardData,
          deliveryMethod: PhysicalCardTypes.Custom,
        })
        onContinue(true)
      }
    }

    !!window.location.search && getQueryData()
  }, [window.location.search])

  const handleUpdate = deliveryMethod => () => {
    setPhysicalCardData({
      ...physicalCardData,
      deliveryMethod,
    })
  }

  return (
    <div className={styles.container}>
      <RedBar />
      <div className={styles.content}>
        <div className={styles.title}>Let's prepare your mailed gift cards</div>
        <div className={styles.typeSelector}>
          <div className={styles.divCustomCards}>
            <Button
              outline
              color={deliveryMethod === PhysicalCardTypes.ToMe ? "yellow" : "red"}
              onClick={handleUpdate(PhysicalCardTypes.ToMe)}>
              Ship To Me
            </Button>
          </div>
          <div className={styles.divCustomCards}>
            <Button
              outline
              color={
                deliveryMethod === PhysicalCardTypes.ToRecipient
                  ? "yellow"
                  : "red"
              }
              onClick={handleUpdate(PhysicalCardTypes.ToRecipient)}>
              Ship To Them
            </Button>
          </div>
          <div className={styles.divCustomCards}>
            <Button
              outline
              color={
                deliveryMethod === PhysicalCardTypes.Custom ? "yellow" : "red"
              }
              onClick={handleUpdate(PhysicalCardTypes.Custom)}>
              Order Custom Cards
              <i className="fa-solid fa-pen-to-square ml-3"></i>
            </Button>
            <br />
            <div className={styles.infoLabel}>
              Minimum order: <strong>100 cards</strong> or <b>$1500</b>
            </div>
          </div>
          <div className={styles.infoBtn}>
            <i className="fa-solid fa-circle-info"></i>
          </div>
        </div>

        <Button className={styles.btnContinue} onClick={onContinue}>
          Continue
        </Button>
      </div>
    </div>
  )
}

export default Onboarding
