import classnames from "classnames"
import React from "react"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Button from "../Button"
import styles from "./styles.module.scss"

function OccasionSelector({ className, occasion, occasions, onChange }) {
  let label = "Select Your Occasion"
  if (occasion?.OccasionId !== 54 && !!occasion?.OccasionName) {
    label = occasion?.OccasionName
  }
  const items = occasions?.filter(({ OccasionId }) => OccasionId !== 54)
  const customImage = occasions?.find(({ OccasionId }) => OccasionId === 54)

  return (
    <>
      <UncontrolledDropdown
        className={classnames(styles.container, className, {
          [styles.selected]: occasion && occasion.OccasionId !== 54
        })}
        direction="down"
      >
        <DropdownToggle nav caret className={styles.btnToggle}>
          {label}
        </DropdownToggle>
        <DropdownMenu className={styles.content}>
          {items?.filter(({ IsPopular }) => IsPopular).map((item, index) => (
            <DropdownItem key={index} onClick={() => onChange(item)}>
              <span className="nav-link">{item.OccasionName}</span>
            </DropdownItem>
          ))}
          <DropdownItem divider />
          {items?.filter(({ IsPopular }) => !IsPopular).map((item, index) => (
            <DropdownItem key={index} onClick={() => onChange(item)}>
              <span className="nav-link">{item.OccasionName}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      Or
      <Button
        className={styles.btnCustomImage}
        outline
        color={occasion?.OccasionId === 54 ? "yellow" : "red"}
        onClick={() => onChange(customImage)}
      >
        Custom Image
      </Button>
    </>
  )
}

export default OccasionSelector
