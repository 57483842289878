import React, { useState } from "react"
import { Button } from "../../.."
import { usePurchaseData } from "../../../../Context/purchase"
import Modal from "../../../Shared/Modal"
import UploadCustomImage from "../../../Common/UploadCustomImage"
import CustomPhysicalCardInstructionsModalContent from "../../../Common/CustomPhysicalCardInstructionsModalContent"
import styles from "./styles.module.scss"

function CustomArt({ onContinue }) {
  const [openModal, setOpenModal] = useState(false)
  const { physicalCardData, setPhysicalCardData } = usePurchaseData()
  const { customLogoFile } = physicalCardData

  const handleUploadImage = file => {
    setPhysicalCardData({
      ...physicalCardData,
      customLogoFile: file,
    })
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>Upload your artwork</div>
      <div className={styles.actions}>
        <Button
          className={styles.btnInstructions}
          outline
          color="gray"
          onClick={() => setOpenModal(true)}>
          Instructions
        </Button>
        <a
          download
          name="downloadTemplate"
          className={styles.downloadWrapper}
          href="/Account/Orders/FilesForDownload/CustomCardArtTemplate.pdf">
          <Button className={styles.btnAction} outline color="gray">
            Template
            <i className="fa-solid fa-file-arrow-down"></i>
          </Button>
        </a>
      </div>
      <div className={styles.uploadWrapper}>
        <div className={styles.preview}>
          {customLogoFile ? (
            <img src={customLogoFile.secure_url} alt="Custom Art" />
          ) : (
            <i className="fa-solid fa-image"></i>
          )}
        </div>
        <div className={styles.uploadWidget}>
          <UploadCustomImage showGui={false} onChange={handleUploadImage}>
            <Button
              className={styles.btnAction}
              size="small"
              outline
              color="red">
              Upload Here
              <i className="fa-solid fa-image"></i>
            </Button>
          </UploadCustomImage>
          {customLogoFile && (
            <div className={styles.uploadDetail}>
              <div className={styles.fileLabel}>Uploaded File</div>
              <div className={styles.fileDetail}>
                <img src={customLogoFile.secure_url} alt="Custom Logo" />
                {customLogoFile.original_filename}.
                {customLogoFile.original_extension}
              </div>
            </div>
          )}
        </div>
      </div>
      <Button className={styles.btnContinue} onClick={onContinue}>
        Let's Continue
      </Button>

      <Modal isOpen={openModal} size="md" onClose={() => setOpenModal(false)}>
        <CustomPhysicalCardInstructionsModalContent />
      </Modal>
    </div>
  )
}

export default CustomArt
