import React from "react"
import styles from "./styles.module.scss"

export default function HistoryBanner() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Since 2004, we have helped raise millions of dollars for causes like these…
      </div>
    </div>
  )
}
