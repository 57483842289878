import React, { useRef } from "react"
import RedBarFullSize from "./FullSize";
import RedBarMini from "./Mini";

export default function RedBar(props) {
  const innerWidth = useRef(window.innerWidth);
  const showMinimized = innerWidth.current < 1080;
  return showMinimized
    ? <RedBarMini  {...props} />
    : <RedBarFullSize  {...props} />
}