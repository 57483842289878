import React, { useEffect, useState } from "react"
import { GetRedemptionPage } from "../../Code/Data"
import { useRedemptionData } from "../../Context/redemption"
import { useUserData } from "../../Context/user"
import RequireLogin from "../Common/RequireLogin"
import SuccessCreateUserRedemptionPage from "./Success"
import Steps from "./Steps"

function CreateUserRedemptionPage() {
  const [success, setSuccess] = useState(false)
  const { loggedInUser } = useUserData()
  const { setRedemptionPageData } = useRedemptionData()
  const [isEditMode, setEditMode] = useState(false)
  const [hasExisting, setHasExisting] = useState(false)

  useEffect(() => {
    async function fetchRedmptionPageData() {
      const { Succeeded, UserRedemptionPageInfo } = await GetRedemptionPage(
        loggedInUser.UserId
      )
      setHasExisting(!!Succeeded)
      if (Succeeded) {
        setRedemptionPageData({
          ...UserRedemptionPageInfo,
        })
      }
    }

    loggedInUser?.UserId && fetchRedmptionPageData()
  }, [loggedInUser?.UserId])

  return <RequireLogin message="Please login to customize your recipients redemption experience">
    {success
      ? <SuccessCreateUserRedemptionPage setEditMode={setEditMode} />
      : <Steps onSuccess={() => setSuccess(true)} setEditMode={setEditMode} isEditMode={isEditMode} hasExisting={hasExisting} />
    }
  </RequireLogin>
}

export default CreateUserRedemptionPage
