import React, { useRef } from "react"
import LandingPageCarouselBig from "./LandingPageCarouselBig/index.js";
import LandingPageCarouselSmall from "./LandingPageCarouselSmall/index.js";

export default function LandingPageCarousel(props) {
    const innerWidth = useRef(window.innerWidth);
    const showMinimized = innerWidth.current < 1080;
    return showMinimized
        ? <LandingPageCarouselSmall  {...props} />
        : <LandingPageCarouselBig  {...props} />
}