import React from "react"
import { Modal } from "reactstrap"
import styles from "./styles.module.scss"

export default function CustomModal({ isOpen, size = "lg", onClose, children }) {
  return (
    <Modal
      contentClassName={styles.modalContent}
      centered
      size={size}
      isOpen={isOpen}
      toggle={onClose}
    >
      <div className={styles.btnClose} onClick={onClose}>
        <i className="fa-solid fa-xmark" />
      </div>
      {children}
    </Modal>
  );
}
