import React, { useState } from "react"
import { Link } from "react-router-dom"
import MainPage from "../Components/Shared/MainPage"
import TextButton from "../Components/Common/TextButton"
import ContactForm from "../Components/Contact/ContactForm"

export default function ContactPage() {
  const [openGuidelines, setOpenGuidelines] = useState(false)

  return (
    <MainPage title="Contact Us">
      <div className="form-panel px-1 mb-5 px-sm-5 container-fluid">
        <ul>
          <li>
            <b>For Visitors to Suggest another Charity:</b> Suggest a charity...{" "}
            <a href="/popups/SuggestCharity.aspx">click here</a>
          </li>
          <li>
            <b>For Charities:</b>
            <ul>
              <li>
                Login to a <a href="/Login">Charity Account.</a>
              </li>
              <li>
                Apply to add your charity to our website list, click on{" "}
                <a href="/AddNatLocalCharitiesRegistration/">
                  Charity Application
                </a>
              </li>
              <li>
                Please review our{" "}
                <TextButton
                  title="Charity Guidelines"
                  onClick={() => setOpenGuidelines(!openGuidelines)}
                />
                {openGuidelines && (
                  <div className="pl-3 pb-5">
                    <p>
                      Non-sectarian 501c3 charities may apply. The primary
                      mission of any applicant should be one of social welfare,
                      amongst those on our <Link to="/">category list.</Link>
                    </p>
                    <p>
                      We do not list religious institutions and programs, e.g.,
                      churches, synagogues, mosques, temples – parochial
                      schools, clubs and camps. Faith-based charities with a
                      primary mission of social welfare, rather than religion,
                      may apply for consideration.
                    </p>
                    <p>
                      Nor do we list organizations that are dedicated to, or a
                      significant aspect of their mission is cause advocacy or
                      political orientation.
                    </p>
                    <p>
                      Acceptance is at the sole discretion of our board of
                      trustees and may be changed at any time.
                    </p>
                    <p>
                      <b>Note:</b> Applicant may be a local branch of one of the
                      charities on our 'List of over 250 charities'.
                    </p>
                  </div>
                )}
              </li>
            </ul>
          </li>
        </ul>
        <ContactForm />
        <p>
          Charity Gift Certificates
          <br />
          111 Autumn Rd.
          <br />
          Lakewood, NJ 08701
          <br />
          Email Address:{" "}
          <a href="mailto:info@charitygiftcertificates.org">
            info@charitygiftcertificates.org
          </a>
        </p>
      </div>
    </MainPage>
  )
}
