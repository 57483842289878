import React, { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import { Nav, NavLink, Modal, ModalHeader, ModalBody } from "reactstrap"
import { scrollToElement } from "../../Code/Utilities"
import { AccountTypes } from "../../Code/Data"
import { useCartData } from "../../Context/cart"
import { useUserData } from "../../Context/user"

export default function MainMenu({ barsClassName }) {
  const [menuVisible, setMenuVisible] = useState(false)
  const { loggedInUser, logout } = useUserData()
  const { cart } = useCartData()

  const toggleModal = () => setMenuVisible(!menuVisible)

  const handleGotoSection = sectionId => () => {
    scrollToElement(`#${sectionId}`, true, 0)
    toggleModal()
  }

  const accountType = loggedInUser?.AccountType
  const cartHasItems = cart?.Items?.length > 0

  return (
    <>
      <Link className={barsClassName || "text-light fs-3"} onClick={toggleModal} to="#">
        <i className={"fa fa-bars"} />
      </Link>
      <Modal
        className="side-menu-modal"
        isOpen={menuVisible}
        toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {loggedInUser ? (
            <h3>Welcome {loggedInUser.FirstName}!</h3>
          ) : (
            <h3>CharityChoice Main Menu</h3>
          )}
        </ModalHeader>
        <ModalBody role="navigation">
          <NavLink href="/Redeem">Customize your Redeem Page</NavLink>
          <hr />
          <p>Purchase or Donate</p>
          <Nav vertical>
            <NavLink tag={Link} to="/Purchase/DigitalCards">
              Digital Cards
              <sub>100 graphics or upload your own</sub>
            </NavLink>
            <NavLink tag={Link} to="/Purchase/PrintCards">
              Printable Cards
              <sub>100 graphics or upload your own</sub>
            </NavLink>
            <NavLink href="/Purchase/PhysicalCards">
              Physical Gift Cards
              <sub>Our in-stock physical cards, designs for many occasions</sub>
            </NavLink>
            <NavLink tag={Link} to="/Purchase/PhysicalCards?type=3">
              Custom Gift Cards
              <sub>
                Bulk custom physical card orders - customized with your own
                design or logo
              </sub>
            </NavLink>
            <NavLink href="/Purchase/RedemptionCodes">
              Get Active Redemption Codes
            </NavLink>
            <NavLink href="/Purchase/Favors-Swag-QR-Codes">
              Multi-Redeemable QR Codes
            </NavLink>
            <NavLink href="/Purchase/HonorCards">
              HonorCards
              <sub>Donate to a specific charity</sub>
            </NavLink>
            <NavLink href="/Purchase/DirectToCharityDonation">
              Make a Donation to Charity
            </NavLink>
            <NavLink href="/DAF">About DAF</NavLink>
            <NavLink href="/Purchase/DonateYourCard">Donate a Merchant Gift Card</NavLink>
          </Nav>
          <hr />
          <p>Corporate and Events</p>
          <Nav vertical>
            <NavLink tag={Link} to="/CorporateSolutions" onClick={toggleModal}>
              Corporate Solutions
            </NavLink>
            <NavLink href="/customPageAdmin/CreateCustomRedemptionPage">
              Online Corporate Customization Wizard
              <sub>
                Custom redemption pages for your business gifts (needs corporate
                account)
              </sub>
            </NavLink>
            <NavLink tag={Link} to="/CorporateSolutions">Events &amp; Meetings</NavLink>
            <NavLink href="/BridalRegistry">
              Wedding Registry and Bridal Favors
            </NavLink>
          </Nav>
          <hr />
          <p>Our Charities</p>
          <Nav vertical>
            <NavLink href="/Charities.aspx">
              List of our Charities
              <sub>View our list of Registered Charities</sub>
            </NavLink>
            <NavLink href="/AddNatLocalCharitiesRegistration/">
              Charity Application
              <sub>Register your charity on our list</sub>
            </NavLink>
            <NavLink href="/CharityLogin.aspx">
              Charity Login
              <sub>
                Login to modify your charity account, download reports, user
                optin info, etc.
              </sub>
            </NavLink>
            <NavLink href="/Account/CreateUserRedemptionPage/">
              Customize your Redemption Page
              <sub>Branded page</sub>
            </NavLink>
          </Nav>
          {accountType === AccountTypes.Personal && (
            <Fragment>
              <hr />
              <p>Personal Account</p>
              <Nav vertical>
                <NavLink href="/Account/Update">Modify My Account</NavLink>
                <NavLink href="/Account/Orders">My Orders</NavLink>
              </Nav>
            </Fragment>
          )}
          {accountType === AccountTypes.Charity && (
            <Fragment>
              <hr />
              <p>Charity Account</p>
              <Nav vertical>
                <NavLink href="/Account/Update">Modify My Account</NavLink>
                <NavLink href="/Reports/CharityDistributionReport.aspx">
                  Charity Distribution Reports
                </NavLink>
                <NavLink href="/Account/Update#CharityInformation">
                  Upload or Change Logo
                </NavLink>
                <NavLink href="/Purchase/DonateYourCard">
                  Accept Donations of Retail Gift Cards
                </NavLink>
              </Nav>
            </Fragment>
          )}
          {accountType === AccountTypes.Corporate && (
            <Fragment>
              <hr />
              <p>Corporate Account</p>
              <Nav vertical>
                <NavLink href="/Account/Update">Modify My Account</NavLink>
                <NavLink href="/Account/Orders">My Orders</NavLink>
                <NavLink href="/customPageAdmin/CreateCustomRedemptionPage">
                  Online Corporate Customization Wizard
                  <sub>
                    Fully customizable redemption pages for your business gifts (needs corporate
                    account)
                  </sub>
                </NavLink>
              </Nav>
            </Fragment>
          )}
          <hr />
          <NavLink onClick={handleGotoSection("GiveGiftCards")}>
            Purchase
          </NavLink>
          {cartHasItems && (
            <Fragment>
              <NavLink href="/Purchase/ShoppingCart">My Cart</NavLink>
              <NavLink href="/Purchase/ShoppingCart">Check Out</NavLink>
            </Fragment>
          )}
          {loggedInUser ? (
            <NavLink
              onClick={() => {
                logout()
                toggleModal()
              }}>
              Log Out of Account
            </NavLink>
          ) : (
            <NavLink href="/Login">Account Login</NavLink>
          )}
          <NavLink href="/FAQs.aspx">FAQs, Terms &amp; Conditions</NavLink>
          <NavLink
            href="https://charitychoicegc.wixsite.com/charitychoice"
            target="__blank">
            Blog
          </NavLink>
          <NavLink onClick={handleGotoSection("sitemap")}>
            Full Directory
          </NavLink>
        </ModalBody>
      </Modal>
    </>
  )
}
