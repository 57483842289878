import React, { Fragment } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";

export default class AdditionalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseId: "",
      redemptionCode: "",
      dateOfGift: "",
      sourceOfGift: "",
      giftRecipientName: "",
      donationAmount: "",
    };
  }

  handleChange = ({ target }) => {
    const { onChange } = this.props;
    this.setState({ [target.name]: target.value }, () => onChange(this.state));
  };
  render() {
    const { type } = this.props;
    const {
      purchaseId,
      redemptionCode,
      dateOfGift,
      sourceOfGift,
      giftRecipientName,
      donationAmount,
    } = this.state;
    return (
      <Fragment>
        <h4>
          <u>Additional Information:</u>
        </h4>
        <Row className="mt-3">
          {type === "Purchase Gift Cards" && (
            <Col sm={6}>
              <FormGroup className="has-float-label">
                <Input
                  type="number"
                  name="purchaseId"
                  id="purchaseId"
                  placeholder="Purchase Id"
                  defaultValue={purchaseId}
                  onChange={this.handleChange}
                />
                <Label for="purchaseId">Purchase Id</Label>
              </FormGroup>
            </Col>                        
          )}
          {type === "Redeem A Gift Card" && (
            <Col sm={6}>
              <FormGroup className="has-float-label">
                <Input
                  name="redemptionCode"
                  id="redemptionCode"
                  placeholder="Redemption Code"
                  defaultValue={redemptionCode}
                  onChange={this.handleChange}
                />
                <Label for="redemptionCode">Redemption Code</Label>
              </FormGroup>
            </Col>
          )}
          <Col sm={6}>
            <FormGroup className="has-float-label">
              <Input
                type="date"
                name="dateOfGift"
                id="dateOfGift"
                placeholder="Date of Gift"
                defaultValue={dateOfGift}
                onChange={this.handleChange}
              />
              <Label for="dateOfGift">Date of Gift</Label>
            </FormGroup>
          </Col>
          {type === "Redeem A Gift Card" && (
            <Col sm={6}>
              <FormGroup className="has-float-label">
                <Input
                  name="sourceOfGift"
                  id="sourceOfGift"
                  placeholder="Source Of Gift"
                  defaultValue={sourceOfGift}
                  onChange={this.handleChange}
                />
                <Label for="sourceOfGift">Source Of Gift</Label>
              </FormGroup>
            </Col>
          )}
          {type === "Purchase Gift Cards" && (
            <Col sm={6}>
              <FormGroup className="has-float-label">
                <Input
                  name="giftRecipientName"
                  id="giftRecipientName"
                  placeholder="Gift Recipient"
                  defaultValue={giftRecipientName}
                  onChange={this.handleChange}
                />
                <Label for="giftRecipientName">Gift Recipient</Label>
              </FormGroup>
            </Col>
          )}
          <Col sm={6}>
            <FormGroup className="has-float-label">
              <Input
                type="number"
                name="donationAmount"
                id="donationAmount"
                placeholder="Donation Amount"
                defaultValue={donationAmount}
                onChange={this.handleChange}
              />
              <Label for="donationAmount">Donation Amount</Label>
            </FormGroup>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
