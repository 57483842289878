import React, { useEffect, useState } from "react"
import { AccountTypes } from "../../Code/Data"
import { useUserData } from "../../Context/user"
import { Onboarding, Steps } from "../../Components/DigitalCards"
import { PageHit } from "../../Code/Data"
import { parseQuerystring } from "../../Code/Utilities"

function DigitalCards() {
  const [gettingStarted, setGettingStarted] = useState()
  const [isCorporate, setIsCorporate] = useState(false)
  const { loggedInUser } = useUserData()

  useEffect(() => {
    if (window.location.search.length > 3) {
      PageHit(parseQuerystring(window.location.search));
    }
  }, []);

  useEffect(() => {
    if (loggedInUser) {
      const { AccountType } = loggedInUser
      setIsCorporate(AccountType === AccountTypes.Corporate)
      setGettingStarted(true)
    }
  }, [loggedInUser])

  const handleGettingStarted = corporate => {
    setIsCorporate(corporate)
    setGettingStarted(true)
  }

  const handleBackStart = () => {
    setGettingStarted(false)
  }

  if (!gettingStarted) {
    return <Onboarding onContinue={handleGettingStarted} />
  }

  return <Steps isCorporate={isCorporate} onBack={handleBackStart} />
}

export default DigitalCards
