import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import MainPage from "../Components/Shared/MainPage";

export default function AfghanistanRefugees(props) {
  return (
    <MainPage title="You can help Afghanistan Refugees">
      <div className="px-1 mb-5 px-sm-5 container-fluid">
        <p>
        Many organizations are at the forefront of caring for those affected by the crisis.
          <br />
          CharityChoice gives you the opportunity to join in with their efforts.
          <br />
          <a href="Purchase/DigitalCards/">Digital eGifts</a>, delivered via email, text, etc., are especially good to send at this time.
        </p>
        <p>
          These charities, listed on our website, are amongst those providing
          critically needed services.
        </p>
        <p>
          <h3>Charities</h3>
          <ul>
            <li>Operation Recovery</li>
            <li>Preemptive Love Coalitions</li>
            <li>UNICEF</li>
            <li>Islamic Relief USA</li>
            <li>Catholic Relief Services</li>
            <li>Women for Women International</li>
            <li>Save the Children</li>
            <li>CARE</li>
            <li>Good360</li>
          </ul>
        </p>
        <p>
          <h3>Ways To Help</h3>
          <Container>
            <Row xs={12}>
              <Col
                tag="a"
                xs="12"
                md="6"
                lg="4"
                className="mb-1 mb-sm-3"
                href="/Purchase/DigitalCards/">
                <Card className="charity-card-small">
                  <CardHeader>Charity Gift Cards</CardHeader>
                  <CardBody className="popover-body">
                    Keeping in touch with friends and family in a meaningful,
                    thoughtful way.
                  </CardBody>
                </Card>
              </Col>
              <Col
                tag="a"
                xs="12"
                md="6"
                lg="4"
                className="mb-1 mb-sm-3"
                href="/Purchase/HonorCards/">
                <Card className="charity-card-small">
                  <CardHeader>Honor Cards</CardHeader>
                  <CardBody className="popover-body">
                    Pre-designate a card for a charity you select. Send a card
                    noting the donation on their behalf.
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" md="6" lg="4" className="mb-1 mb-sm-3">
                <Link to="/CorporateSolutions">
                  <Card className="charity-card-small">
                    <CardHeader>Corporate Initiatives</CardHeader>
                    <CardBody className="popover-body">
                      Keep in touch with clients during this time of social
                      distancing.
                      <br />
                      Also, utilize to add meaning to your 'virtual events'.
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col
                xs="12"
                md="6"
                lg="4"
                className="mb-1 mb-sm-3"
                tag="a"
                href="/Purchase/DirectToCharityDonation/">
                <Card className="charity-card-small">
                  <CardHeader>Direct Donations</CardHeader>
                  <CardBody className="popover-body">
                    You can donate to organizations via CharityChoice.
                  </CardBody>
                </Card>
              </Col>
              <Col
                xs="12"
                md="6"
                lg="4"
                className="mb-1 mb-sm-3"
                tag="a"
                href="/RewardsRedeemPoints/">
                <Card className="charity-card-small">
                  <CardHeader>
                    <a href="/RewardsRedeemPoints/">Redeem your Points</a>
                  </CardHeader>
                  <CardBody className="popover-body">
                    Order a CharityChoice card from you rewards or incentive
                    program.
                    <br />
                    Then designate it on our website for the charity you select.
                  </CardBody>
                </Card>
              </Col>
              <Col
                xs="12"
                md="6"
                lg="4"
                className="mb-1 mb-sm-3"
                tag="a"
                href="/Purchase/BulkDiscount/">
                <Card className="charity-card-small">
                  <CardHeader>Bulk Discounts - Discreet Gifting</CardHeader>
                  <CardBody className="popover-body">
                    Send a non-denominated charity gift to all of your friends
                    and family. Economical and charitable! Touch base with
                    clients. Meaningful and cost-effective!
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <h5 className="mt-5">
            You can also donate unused balances of retail, restaurant gift cards
            at
            <a href="/Purchase/DonateYourCard"> www.DonateYourCard.org</a>
          </h5>
          <Container className="mt-5">
            <Row>
              <Col
                md={6}
                xs="12"
                className="mb-5 buy-now-button text-center justify-content-center">
                <Button
                  color="primary"
                  className="d-md-block"
                  href="/#GiveGiftCards">
                  Purchase a Charity Gift
                </Button>
              </Col>
              <Col
                md={6}
                xs="12"
                className="mb-5 buy-now-button text-center justify-content-center">
                <Button color="primary" className="d-md-block" href="/Redeem/">
                  Redeem a Gift Card
                </Button>
              </Col>
            </Row>
          </Container>
        </p>
      </div>
    </MainPage>
  );
}
