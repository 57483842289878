import React, { useState } from "react"
import { ChromePicker } from "react-color"
import styles from "./styles.module.scss"

export default function ColorPicker({ title, foreColor, backColor, onChange }) {
  const [openPicker, setOpenPicker] = useState(false)

  const handleChange = ({ hex }) => {
    onChange(hex)
  }
  return (
    <div className={styles.container}>
      <div
        className={styles.trigger}
        style={{ color: foreColor, backgroundColor: backColor }}
        onClick={() => setOpenPicker(true)}>
       {title}
        <i className="fa-solid fa-palette"></i>
      </div>
      {openPicker && (
        <div className={styles.pickerWrapper}>
          <div
            className={styles.backdrop}
            onClick={() => setOpenPicker(false)}
          />
          <ChromePicker
            disableAlpha
            color={backColor}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  )
}
