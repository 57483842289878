import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { getGuid } from "../../Code/Utilities"


export default class PayPalSDK extends React.Component {
  static propTypes = {
    clientId: PropTypes.string,
    addToDonationAmount: PropTypes.string,
    donationId: PropTypes.string,
    amount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    currency: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    shippingPreference: PropTypes.string,
    onSuccess: PropTypes.func,
    catchError: PropTypes.func,
    onError: PropTypes.func,
    createOrder: PropTypes.func,
    createSubscription: PropTypes.func,
    createBillingAgreement: PropTypes.func,
    onApprove: PropTypes.func,
    style: PropTypes.object,
    options: PropTypes.shape({
      clientId: PropTypes.string,
      merchantId: PropTypes.string,
      currency: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      intent: PropTypes.string,
      commit: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
      ]),
      vault: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
      ]),
      components: PropTypes.string,
      disableFunding: PropTypes.string,
      disableCard: PropTypes.string,
      enableFunding: PropTypes.string,
      integrationDate: PropTypes.string,
      locale: PropTypes.string,
      buyerCountry: PropTypes.string,
      debug: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
      ]),
    }),
    showMessages: PropTypes.bool,
    showButtons: PropTypes.bool,
    onButtonReady: PropTypes.func,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
  }

  static defaultProps = {
    style: {},
    options: {
      currency: "USD",
    },
    shippingPreference: "GET_FROM_FILE",
  }

  constructor(props) {
    super(props)

    this.state = {
      isSdkReady: false,
      components: null,
    }

    //this.setScriptTag = this.setScriptTag.bind(this);
  }

  componentDidMount() {
    if (!this.state.isSdkReady) {
      const { clientId, components, options, onButtonReady, showMessages, showButtons } = this.props,
        scriptOptions = options || {}

      if (!scriptOptions.clientId && clientId) {
        scriptOptions.clientId = clientId
      }
      if (!scriptOptions.clientId) {
        scriptOptions.clientId = "sb" //sandbox
      }
      if (!scriptOptions.components && components) {
        scriptOptions.components = components
      }
      if (!scriptOptions.components) {
        scriptOptions.components = (showMessages === false ? "" : "messages")
      }
      if (showButtons && !scriptOptions.components.toLowerCase().includes("buttons")) {
        scriptOptions.components += ",buttons"
      }
      this.setState({ components: scriptOptions.components })
      addPaypalSdk(scriptOptions, () => {
        this.setState({ isSdkReady: true })
        if (onButtonReady)
          onButtonReady()
      })
    } else if (
      typeof window !== "undefined" &&
      window !== undefined &&
      window.paypal !== undefined &&
      this.props.onButtonReady
    ) {
      this.props.onButtonReady()
    }
  }

  createOrder(data, actions) {

    const { donationId, currency, options, amount, shippingPreference, addToDonationAmount } = this.props
    if (parseInt(addToDonationAmount) > 0) {
      return actions.order.create({
        purchase_units: [
          {
            reference_id: "r3e7gb",
            amount: {
              currency_code: "USD",
              value: addToDonationAmount.toString(),
            },
          },
        ],
      })
    }
    return actions.order.create({
      purchase_units: [
        {
          reference_id: `${donationId || "00000"}  ${getGuid()}`,
          amount: {
            currency_code: currency
              ? currency
              : options && options.currency
                ? options.currency
                : "USD",
            value: amount.toString(),
          },
        },
      ],
      application_context: {
        shipping_preference: shippingPreference,
      },
    })
  }

  onApprove(data, actions) {
    return actions.order
      .capture()
      .then((details) => {
        if (this.props.onSuccess) {
          return this.props.onSuccess(details, data)
        }
      })
      .catch((err) => {
        if (this.props.catchError) {
          return this.props.catchError(err)
        }
      })
  }

  render() {
    const { components } = this.state

    if (
      !components ||
      !components.includes("buttons") ||
      typeof window.paypal === "undefined" ||
      window.paypal === undefined ||
      typeof window.paypal.Buttons === "undefined" ||
      window.paypal.Buttons === undefined
    ) {
      return null
    }


    const {
      amount,
      onSuccess,
      createOrder,
      createSubscription,
      createBillingAgreement,
      onApprove,
      style,
      onClick,
      onCancel,
    } = this.props

    const Button = window.paypal.Buttons.driver("react", {
      React,
      ReactDOM,
    })

    const createOrderFn =
      amount && !createOrder
        ? (data, actions) => this.createOrder(data, actions)
        : (data, actions) => createOrder(data, actions)

    return (
      <Button
        {...this.props}
        createOrder={(createSubscription || createBillingAgreement) ? undefined : createOrderFn}
        createSubscription={createSubscription}
        createBillingAgreement={createBillingAgreement}
        onApprove={
          onSuccess
            ? (data, actions) => this.onApprove(data, actions)
            : (data, actions) => onApprove(data, actions)
        }
        style={style}
        onClick={onClick}
        onCancel={onCancel}
      />
    )
  }
}

function addPaypalSdk(options, onSDKReady) {
  const queryParams = []
  // replacing camelCase with dashes
  Object.keys(options).forEach(k => {
    const name = k.split(/(?=[A-Z])/).join("-").toLowerCase()
    queryParams.push(`${name}=${options[k]}`)
  })

  const url = `https://www.paypal.com/sdk/js?${queryParams.join("&")}`

  if (document.querySelectorAll("[src=\"" + url + "\"]").length === 0) {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = url
    script.async = true
    script.onload = () => {
      if (onSDKReady) {
        onSDKReady()
      }
    }
    script.onerror = () => {
      throw new Error("Paypal SDK could not be loaded.")
    }

    document.body.appendChild(script)
  }
}
