import React, { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { parseQuerystring } from "../../Code/Utilities"
import { SuccessRedemption, Steps } from "../../Components/RedeemCodes"
import { useRedemptionData } from "../../Context/redemption"

function RedeemCodes() {
  const [success, setSuccess] = useState()
  const {
    redeemCodeInfo,
    redemptionPageInfo,
    getRedeemCodeInfo,
    getScrambledRedeemCode,
    getRedeemInfoFromServerSession,
    getRedemptionPageInfo,
  } = useRedemptionData()
  const { search } = useLocation()
  const history = useHistory()

  useEffect(() => {
    const setStuff = async () => {
      const { RC, SC, US, UID, SUCCESS } = parseQuerystring(search.toUpperCase())
      if (RC && redeemCodeInfo?.RedeemCode?.toUpperCase() !== RC.toUpperCase()) {
        await getRedeemCodeInfo(RC)
      }
      else if (SC) {
        await getScrambledRedeemCode(SC)
      }
      else if (US === "1") {
        await getRedeemInfoFromServerSession()
      }
      if (UID && redemptionPageInfo?.UserId !== parseInt(UID)) {
        getRedemptionPageInfo(UID)
      }
      setSuccess(SUCCESS)
    }
    setStuff()
  }, [search])

  if (!!redemptionPageInfo) {
    //The user redemption page is without the header and footer, so we reload everything
    console.info("Redirecting from /Redeem/ to /Redeem/Custom/")
    history.push("/Redeem/Custom/")
  }
  else {
    return success
      ? <SuccessRedemption isAddToDonation={false}/>
      : <Steps setSuccess={setSuccess} />
  }
}

export default RedeemCodes
