import React from "react"
import { readRecipCsvFile } from "../../../Code/Utilities"
import styles from "./styles.module.scss"

export default function RecipientsUploader({ deliveryMethod, onUpload }) {
  const handleUpload = async ({ target }) => {
    const { files } = target
    if (files && files.length) {
      try {
        const [file] = files
        const { recipients, errorLines } = await readRecipCsvFile(file, deliveryMethod)             
        onUpload(recipients, errorLines);
      } catch (e) {
        console.log(e.message)
      }
      //Now reset the file input, in order to allow the user to re-upload the same file name.
      target.value = ""
      target.type = ""
      target.type = "file"
    }
  }
  return (
    <div>
      <input
        type="file"
        id="fileUploadRecips"
        accept=".csv"
        style={{ display: "none" }}
        onChange={handleUpload}
      />
      <label htmlFor="fileUploadRecips">
        <div className={styles.btnUpload}>
          Upload Recipients
          <i className="fa-solid fa-file-arrow-up"></i>
        </div>
      </label>     
    </div>
  )
}