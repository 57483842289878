import React, { useEffect, useState } from "react"
import MainPage from "../Components/Shared/MainPage"
import LoadingSpinner from "../Components/Common/LoadingSpinner"
import { get } from "../Code/IApi"
import { parseQuerystring } from "../Code/Utilities"

export default function DynamicPage() {
  const [pageData, setPageData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState(null)

  useEffect(async () => {
    const qsVariables = parseQuerystring(window.location.search)
    if (qsVariables.page) {
      setLoading(true)
      setErrMsg(null)
      const { Content, ErrorMessage } = await get(
        `/generalUtils/dynamicContent/${qsVariables.page}`
      )
      setLoading(false)
      setPageData(Content)
      setErrMsg(ErrorMessage)
    }
  }, [])

  if (loading) {
    return <LoadingSpinner text="Loading Page..." />
  } else if (!!errMsg) {
    return <MainPage title={errMsg} />
  } else if (!pageData) {
    return <MainPage title="No Page Found" />
  }

  return (
    <MainPage title={pageData.Title}>
      <div className="p-4 container-fluid">
        <div dangerouslySetInnerHTML={{ __html: pageData.Html }} />
      </div>
    </MainPage>
  )
}
