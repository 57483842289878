import React, { useEffect, useState } from "react"
import { PhysicalCardTypes } from "../../../Code/Data"
import { usePurchaseData } from "../../../Context/purchase"
import RedBar from "../../Shared/RedBar"
import CustomArt from "./CustomArt"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import Step5 from "./Step5"
import styles from "./styles.module.scss"

function Steps() {
  const [step, setStep] = useState(0)
  const [steps, setSteps] = useState([Step1, Step2, Step3, Step4, Step5])
  const { physicalCardData } = usePurchaseData()
  const { deliveryMethod, denominationAmount, numOfCards } = physicalCardData

  useEffect(() => {
    setSteps(
      deliveryMethod === PhysicalCardTypes.Custom
        ? [Step1, CustomArt, Step2, Step3, Step4, Step5]
        : [Step1, Step2, Step3, Step4, Step5],
    )
  }, [deliveryMethod])

  const handleContinue = () => {
    setStep(step + 1)
  }

  const handleBack = () => {
    setStep(step - 1)
  }

  const handleAddMore = () => {
    setStep(0)
  }

  const StepComponent = steps[step]

  return (
    <div className={styles.container}>
      <RedBar>
        <div className=""></div>
        <div className={` ${styles.stepLabel}`}>
          Step {step + 1} of {steps.length}
          {step === steps.length - 1 && (
            <div className={styles.checkoutLabel}>Checkout is next</div>
          )}
          {denominationAmount && numOfCards && (
            <div>
              <i className="fa-solid fa-cart-shopping"></i>
              ${denominationAmount * numOfCards}
            </div>
          )
          }
        </div>
      </RedBar>
      <div className={styles.content}>
        <StepComponent onContinue={handleContinue} onAddMore={handleAddMore} />
        {!!step && (
          <button className={styles.btnStepBack} onClick={handleBack}>
            Go Back
            <i className="fa fa-undo" />
          </button>
        )}
      </div>
    </div>
  )
}

export default Steps
