import React from "react"
import { Helmet } from "react-helmet";
import PropTypes from "prop-types"
import { ShoppingCartItemTypes } from "../../Code/Data"

const CLOUDINARY_CLOUD_NAME = "charity-choice"

export default function UploadCustomImage({
  CardType,
  showGui,
  buttonTitle,
  onChange,
  children,
}) {
  const onImageDrop = async event => {
    event.preventDefault()   
    openWidget()
  }

  const openWidget = () => {
    let cloudinaryUploadPreset = "eCardCustomImages"
    if (CardType === ShoppingCartItemTypes.CustomPhysicalCard) {
      cloudinaryUploadPreset = "CustomPhysicalCardsArt"
    } else if (CardType === ShoppingCartItemTypes.RedemptionCodePurchase) {
      cloudinaryUploadPreset = "UserRedemptionPages"
    }
    else if (CardType === ShoppingCartItemTypes.DigitalCharityCards) {
      cloudinaryUploadPreset = "eCardCustomImages"
    }
    else if (CardType === "CorporateLogos") {
      cloudinaryUploadPreset = "CorporateLogos"
    }

    const myUploadWidget = window.cloudinary.openUploadWidget(
      {
        cloudName: CLOUDINARY_CLOUD_NAME,
        uploadPreset: cloudinaryUploadPreset,
        sources: [
          "local",
          "url",
          "camera",
          "facebook",
          "google_photos",
          "instagram",
        ],
        googleApiKey: "AIrFcR8hKiRo",
        multiple: false,
        maxFileSize: 999999,
        maxImageWidth: 792,
        theme: "white",
        showPoweredBy: false,
        styles: {
          palette: {
            window: "#FFFFFF",
            windowBorder: "#E89D18",
            tabIcon: "#E89D18",
            menuIcons: "#000000",
            textDark: "#FFFFFF",
            textLight: "#FFFFFF",
            link: "#E89D18",
            action: "#E89D18",
            inactiveTabIcon: "#6D6D6D",
            error: "#F44235",
            inProgress: "#0078FF",
            complete: "#20B832",
            sourceBg: "#FDFDFD",
          },
          fonts: {
            default: null,
            "'Fira Sans', sans-serif": {
              url: "https://fonts.googleapis.com/css?family=Fira+Sans",
              active: true,
            },
          },
        },
      },
      (error, result) => {
        if (error) {
          console.error(error)
        }
        if (result && result.event === "success") {
          myUploadWidget.close()
          onChange(result.info)
        }
      }
    )
  }

  return (
    <>
      <Helmet>
        <script src="https://widget.cloudinary.com/v2.0/global/all.js" async="true"></script>
      </Helmet>
      {showGui ? (
        <div id="divFileDrop">
          <p
            className="btn-link d-md-block text-primary text-center display-4"
            onClick={onImageDrop}>
            <strong>
              +<br />
              {buttonTitle}
            </strong>
          </p>
        </div>
      ) : (
        <div className="d-md-block text-primary" onClick={onImageDrop}>
          {children}
        </div>
      )}
    </>
  )
}

UploadCustomImage.propTypes = {
  onChange: PropTypes.func,
  showGui: PropTypes.bool,
  children: PropTypes.any,
}

UploadCustomImage.defaultProps = {
  showGui: true,
  buttonTitle: "Add a photo",
}
