import React from "react"
import { Link } from "react-router-dom"
import MainMenu from "../../../Common/MainMenu"
import { srcLogo } from "../../../../images"
import styles from "./styles.module.scss"

export default function HeaderMini() {
  return (
    <section className={styles.container}>
      <Link className={styles.logo} to="/">
        <img
          src={srcLogo}
          alt="CharityChoice Logo"
          longdesc="Tax deductible Donation Gift Cards. The best charity gift a meaningful, thoughtful way of 'giving back'. Choose from causes who help with animal protection and shelters, environment, homeless, education, food banks, children, disease, research and health, disaster relief � floods, tornados, tsunamis, hurricanes, earthquakes."
        />
      </Link>
      <MainMenu barsClassName={styles.menuBars} />

    </section>
  )
}