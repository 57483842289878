import React, { createContext, useContext, useEffect, useState } from "react"
import { GetCartApi } from "../Code/Data"

const CartContext = createContext()

export const CartProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [cart, setCart] = useState()

  useEffect(() => {
    refreshShoppingCart()
  }, [])

  const refreshShoppingCart = async () => {
    setLoading(true)
    const result = await GetCartApi()
    if (result?.Succeeded && !!result?.ShoppingCart) {
      setCart(result?.ShoppingCart)
    }
    setLoading(false)
  }

  return (
    <CartContext.Provider
      value={{
        loading,
        cart,
        refreshShoppingCart
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCartData = () => useContext(CartContext)
