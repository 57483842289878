import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardImgOverlay,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { get } from "../../Code/IApi"

export default function CharityCard({ charity, selected, onSelect }) {
  const [modalDetail, setModalDetail] = useState(false)
  const [charityInfo, setCharityInfo] = useState({})

  const openDetailModal = async (e, charity) => {
    e.stopPropagation()

    //We need the long description - which is not saved in the local storage charity list. So we need to do an api call.
    const { Succeeded, CharityInfo } = await get(
      `/charity/${charity.CharityId}`
    )
    if (Succeeded) {
      setCharityInfo(CharityInfo)
      setModalDetail(true)
    }
  }

  const {
    CharityName,
    CategoryName,
    Locations,
    ShortDescription,
    LongDescription,
    LogoUrl,
    Url,
    IsLocal,
  } = charityInfo

  return (
    <Col xs="12" md="6" lg="4" className="mb-1 mb-sm-3">
      <Card className={`charity-card small ${selected ? "selected" : ""}`}>
        {charity.LogoUrl ? (
          <img
            src={charity.LogoUrl}
            onError={e => {
              e.target.onerror = null
              e.target.src = "images/default.png"
            }}
            alt={charity.CharityName}
          />
        ) : (
          <div className="img-name">{charity.CharityName}</div>
        )}

        {!onSelect && (
          <CardImgOverlay className="text-right card-overlay">
            <UncontrolledDropdown onClick={e => e.stopPropagation()}>
              <DropdownToggle size="sm" color="primary">
                <i className="fa fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  tag='a'
                  href={`/Purchase?CID=${charity.CharityId}`}>
                  Purchase
                </DropdownItem>
                <DropdownItem
                  tag='a'
                  href={`/Purchase/HonorCards?CID=${charity.CharityId}`}>
                  Honor Cards
                </DropdownItem>
                <DropdownItem
                  tag='a'
                  href={`/Purchase/DirectToCharityDonation?CID=${charity.CharityId}`}>
                  Donate Directly
                </DropdownItem>
                <DropdownItem
                  tag='a'
                  href={`/Redeem?CID=${charity.CharityId}`}>
                  Redeem
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </CardImgOverlay>
        )}
        <CardBody>
          <p className="name">{charity.CharityName}</p>
          <p
            className={`short-desc`}
            style={{
              WebkitBoxOrient: "vertical",
              marginBottom: onSelect ? "4rem" : "1.5rem",
            }}>
            {charity.ShortDescription}
          </p>
          <div className="charity-card-footer mb-3">
            <p
              className="btn-link small mb-1"
              style={{ color: "#C44042" }}
              onClick={e => openDetailModal(e, charity)}>
              Learn More...
            </p>
            {onSelect && (
              <Row className="justify-content-center">
                <Button
                  color={selected ? "success" : "primary"}
                  size="sm"
                  onClick={() => onSelect(charity)}>
                  {selected
                    ? "You have Selected this Charity"
                    : "Select this Charity"}
                </Button>
              </Row>
            )}
          </div>
        </CardBody>
      </Card>
      <Modal
        className="charityDetailModal"
        isOpen={modalDetail}
        toggle={() => setModalDetail(!modalDetail)}>
        <ModalHeader toggle={() => setModalDetail(!modalDetail)}>
          {CharityName}
        </ModalHeader>
        <ModalBody>
          <Card className="charity-card small">
            <div className="position-relative">
              {LogoUrl ? (
                <img
                  src={LogoUrl}
                  onError={e => {
                    e.target.onerror = null
                    e.target.src = "images/default.png"
                  }}
                  alt={CharityName}
                />
              ) : (
                <div className="img-name">{CharityName}</div>
              )}
              {!onSelect && (
                <CardImgOverlay className="text-right">
                  <UncontrolledDropdown onClick={e => e.stopPropagation()}>
                    <DropdownToggle size="sm" color="primary" className="aaaa">
                      <i className="fa fa-ellipsis-v" />
                    </DropdownToggle>

                    <DropdownMenu end>
                      <DropdownItem
                        tag={Link}
                        to={`/Purchase?CID=${charity.CharityId}`}>
                        Purchase
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to={`/Purchase/HonorCards?CID=${charity.CharityId}`}>
                        Honor Cards
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to={`/Purchase/DirectToCharityDonation?CID=${charity.CharityId}`}>
                        Donate Directly
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to={`/Redeem?CID=${charity.CharityId}`}>
                        Redeem
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardImgOverlay>
              )}
            </div>
            <CardBody>
              <p className="name">
                <b>{CharityName}</b>
              </p>
              {CategoryName && (
                <p>
                  <b>Charity Category:</b> {CategoryName}
                </p>
              )}
              {IsLocal && Locations && (
                <p>
                  <b>Location:</b> {Locations.join(", ")}
                </p>
              )}
              <p className="short-desc" style={{ WebkitBoxOrient: "vertical" }}>
                {ShortDescription}
              </p>
              {LongDescription && (
                <p
                  className="short-desc"
                  style={{ WebkitBoxOrient: "vertical" }}>
                  {LongDescription}
                </p>
              )}
              {Url && (
                <a target="_blank" rel="noopener noreferrer" href={Url}>
                  {Url}
                </a>
              )}
              {onSelect && (
                <Row className="mt-3 justify-content-center">
                  <Button
                    color={selected ? "success" : "primary"}
                    className="d-md-block mt-3"
                    size="sm"
                    onClick={() => onSelect(charity)}>
                    {selected
                      ? "You have Selected this Charity"
                      : "Select this Charity"}
                  </Button>
                </Row>
              )}
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </Col>
  )
}
