import React, { Component } from "react";
import { Row } from "reactstrap";
import LoadingSpinner from "../Components/Common/LoadingSpinner";
import CharityCard from "../Components/Charities/CharityCard";
import MainPage from "../Components/Shared/MainPage";
import MainSection from "../Components/Shared/MainSection";
import { get } from "../Code/IApi";

export default class TopCharities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thisMonthList: [],
      allTimeList: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const response = await get("/charity/topCharities");
    this.setState({
      loading: false,
      errMsg: response.ErrorMessage,
      thisMonthList: response.ThisMonthList,
      allTimeList: response.AllTimeList,
    });
  }

  render() {
    const { loading, errMsg, thisMonthList, allTimeList } = this.state;

    if (loading) {
      return <LoadingSpinner />;
    } else if (!!errMsg) {
      return <MainPage title={errMsg} />;
    }

    return (
      <MainPage className="cart" title="Top Chosen Charities">
        <MainSection
          className="container-fluid mb-3"
          title="Top Charities - This Month"
          icon="share-alt">
          <div className="form-panel px-1 px-sm-5 container-fluid">
            <Row className="justify-content-center">
              {thisMonthList.map((charity, index) => (
                <CharityCard key={index} charity={charity} />
              ))}
            </Row>
          </div>
        </MainSection>
        <MainSection
          className="container-fluid mb-3"
          title="Top 5 Charities - All Time"
          icon="share-alt">
          <Row className="justify-content-center">
            {allTimeList.map((charity, index) => (
              <CharityCard key={index} charity={charity} />
            ))}
          </Row>
        </MainSection>
      </MainPage>
    );
  }
}
