import React, { useEffect, useState } from "react"
import { usePurchaseData } from "../../../Context/purchase"
import { DeliveryMethods } from "../../../Code/Data"
import { useUserData } from "../../../Context/user"
import RedBar from "../../Shared/RedBar"
import CustomLogo from "./CustomLogo"
import CardAmount from "./CardAmount"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import Step5 from "./Step5"
import Step6 from "./Step6"
import styles from "./styles.module.scss"

function Steps({ isCorporate, onBack }) {
  const [step, setStep] = useState(0)
  const initialSteps = isCorporate
    ? [Step1, CustomLogo, Step2, Step3, Step4, Step5, Step6]
    : [Step1, Step2, Step3, Step4, Step5, Step6]
  const [steps, setSteps] = useState(initialSteps)
  const { loggedInUser } = useUserData()
  const { digitalCardData } = usePurchaseData()
  const { deliveryMethod } = digitalCardData

  useEffect(() => {
    if (deliveryMethod === DeliveryMethods.Email) {
      setSteps(
        isCorporate
          ? [Step1, CustomLogo, Step2, Step3, Step4, Step5, Step6]
          : [Step1, Step2, Step3, Step4, Step5, Step6]
      )
    } else if (deliveryMethod === DeliveryMethods.Print) {
      setSteps(
        isCorporate
          ? [Step1, CustomLogo, Step2, Step3, CardAmount, Step4, Step6]
          : [Step1, Step2, Step3, CardAmount, Step4, Step6]
      )
    } else {
      setSteps(
        isCorporate
          ? [Step1, CustomLogo, Step2, Step3, Step4, Step6]
          : [Step1, Step2, Step3, Step4, Step6]
      )
    }
  }, [deliveryMethod])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  const handleContinue = () => {
    setStep(step + 1)
  }

  const handleBack = () => {
    step ? setStep(step - 1) : onBack()
  }

  const handleBackToEdit = () => {
    setStep(0)
  }

  const StepComponent = steps[step]

  return (
    <div className={styles.container}>
      <RedBar>
        <div className={styles.stepLabel}>
          Step {step + 1} of {steps.length}
        </div>
      </RedBar>
      <div className={styles.content}>
        <StepComponent
          onContinue={handleContinue}
          onBackToEdit={handleBackToEdit}
        />
        {!(loggedInUser && !step) && (
          <div className={styles.divBackButton}>
            <button className={styles.btnStepBack} onClick={handleBack}>
              Go Back
              <i className="fa fa-undo" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Steps
