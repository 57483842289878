import React from "react"
import Button from "../../Shared/Button"
import styles from "./styles.module.scss"

export default function ReadyToGive() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Ready to give a gift card that <span>matters</span>?
      </div>
      <div className={styles.actions}>
        <a href="/#GiveGiftCards">
          <Button>Buy Charity Gift Card</Button>
        </a>
        <a href="/Purchase/DonateYourCard">
          <Button color="red" outline>Donate Retail Gift Cards</Button>
        </a>
      </div>
    </div>
  )
}
