import classnames from "classnames"
import React from "react"
import styles from "./styles.module.scss"

function Button({
  loading,
  className,
  style,
  color,
  size,
  outline,
  disabled,
  children,
  onClick
}) {
  return (
    <button
      className={classnames(
        styles.container,
        className,
        styles[color],
        styles[size],
        {
          [styles.loading]: loading,
          [styles.outline]: outline,
          [styles.disabled]: disabled,
        }
      )}
      style={style}
      onClick={onClick}
    >
      {children}
      {loading && (
        <div className={styles.spiner}>
          <i className="fas fa-spinner fa-pulse" />
        </div>
      )}
    </button>
  )
}

export default Button
