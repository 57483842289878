import React from "react"
import { Link } from "react-router-dom"
import { Row, Col } from "reactstrap"
import MainPage from "../Components/Shared/MainPage"
import imgSign from "../images/sig.gif"

export default function About() {
  return (
    <MainPage title="About US">
      <div className="px-1 mb-5 px-sm-5 container-fluid">
        <p>
          CharityChoice Gift Cards was born in 2004 when Mark Finkel, a board
          member of our nonprofit organization, wanted to make a charity
          donation in memory of the recently-deceased father of a business
          associate. Not knowing what cause was important to the family, he
          wished to give a charitable gift that allowed the recipient to
          designate the charity of his/her choice.
        </p>
        <p>
          Certainly, he thought, such a service for a meaningful gift - allowing
          the recipient to choose the charity - would be readily available.
          However, an Internet search wasn't fruitful in solving Mark's dilemma.         
          Our next board meeting became the springboard for the website you are
          visiting today, only two months after it was envisioned!
        </p>
        <p>
          Special Kids Fund, an umbrella organization for special needs and
          at-risk youth, is an innovator; helping needy families access
          wheelchair vans for life-changing mobility and creating programs that
          enable the general public 'at large' to participate in helping
          charitable causes on a regular basis.
          <br />
          We believe that foundations, corporations, major philanthropists and{" "}
          <b>every single one of us</b> should be part of the charitable giving
          that truly makes America great!
        </p>
        <p>
          Our CharityChoice Gift Cards indeed provide a way for all of us to
          elevate how we do our gift-giving. We hope that we will now make it
          possible for everyone to add a charitable dimension to their special
          occasion; by giving a charity gift, whenever ...meaningful matters.
        </p>
        <Row className="mt-5">
          <Col xs="12" md="6">
            <p>With best wishes,</p>
            <img
              src={imgSign}
              style={{ width: 200, marginBottom: 20 }}
              alt="Daniel B. Goodman"
            />
            <p>
              Daniel B. Goodman
              <br />
              President, Special Kids Fund
              <br />
              Co - Founder, CharityChoice Gift Cards
            </p>
          </Col>
          <Col xs="12" md="6">
            <p>
              <Link to="/Contact">Contact by email...</Link>
              <br />
              732.397.5056 ~ 111 Autumn Rd.
              <br />
              Lakewood, NJ 08701 <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.specialkidsfund.org/">
                www.SpecialKidsFund.org
              </a>
              <br />
              IRS Tax ID # 58-2550249
            </p>
          </Col>
        </Row>
        <p>
          Your donation gift, non-profit purchase is 100% tax deductible. Your
          gift recipient will receive an eCard and/or printed certificate with a
          redemption code, enabling them to choose from over 100 major
          charities; designating the donation to the charity that they wish.
        </p>
        <p>
          Special Kids Fund is helping to open new vistas for special kids, as a
          <br />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.specialkidsfund.org/">
            {" "}
            children's charity
          </a>{" "}
          alliance, providing for special needs and at-risk youth.
        </p>
      </div>
    </MainPage>
  )
}
