import React, { useState } from "react"
import { Alert } from "reactstrap"
import { Button } from "../../.."
import {
  AddCustomPhysicalCardsApi,
  AddPhysicalCardsApi,
  PhysicalCardTypes,
  UpdateCartTipApi,
} from "../../../../Code/Data"
import { usePurchaseData } from "../../../../Context/purchase"
import { srcMagicMobility } from "../../../../images"
import styles from "./styles.module.scss"

function Step5({ onAddMore }) {
  const [loading, setLoading] = useState()
  const [tipAmount, setTipAmount] = useState(0)
  const [error, setError] = useState()
  const {
    physicalCardData,
    setPhysicalCardData,
    physicalCards,
    setPhysicalCards,
  } = usePurchaseData()
  const {
    index,
    cardType,
    deliveryMethod,
    shippingInfo,
    shippingTypeId,
    numOfCards,
    denomination,
    denominationAmount,
    customLogoFile,
    isMultipleColor,
  } = physicalCardData

  const handleFormatCard = () => {
    var cardData
    if (deliveryMethod === PhysicalCardTypes.Custom) {
      cardData = {
        CustomPhysicalCardTypeId: cardType.CustomPhysicalCardsTypeId,
        ShippingInfo: {
          ...shippingInfo,
          ShippingTypeId: shippingTypeId,
        },
        IsMultipleColor: isMultipleColor,
        Quantity: numOfCards,
        Denomination: denominationAmount,
        ImageURL: customLogoFile.secure_url,
      }
    } else {
      cardData = {
        PhysicalCardTypeId: cardType.PhysicalCardTypeId,
        ShipsToRecipient: deliveryMethod === PhysicalCardTypes.ToRecipient,
        ShippingInfo: {
          ...shippingInfo,
          ShippingTypeId: shippingTypeId,
        },
        Quantity: numOfCards,
        Denomination: denomination.Denomination,
      }
    }
    if (index) {
      cardData.ItemIndex = index
    }
    physicalCards.push(cardData)
    setPhysicalCards([...physicalCards])
    return [...physicalCards]
  }
  const handleAddMore = () => {
    handleFormatCard()
    setPhysicalCardData({})
    onAddMore()
  }

  const handleContinue = async () => {
    const payload = handleFormatCard()

    setLoading(true)
    tipAmount > 0 && (await UpdateCartTipApi(tipAmount))
    const { Succeeded, ErrorMessage } = await (deliveryMethod ===
    PhysicalCardTypes.Custom
      ? AddCustomPhysicalCardsApi(payload)
      : AddPhysicalCardsApi(payload))
    setLoading(false)

    handleFormatCard()
    setPhysicalCardData({})
    setPhysicalCards([])

    if (Succeeded) {
      window.location.href = "/Purchase/ShoppingCart"
    } else {
      setError(ErrorMessage)
    }
  }

  return (
    <div className={`row ${styles.container}`}>

      <div className={`col-3 ${styles.tipDonationContent}`}>
        <i className="fa fa-close" />
        <img src={srcMagicMobility} alt="" height={"100px"} />
        <div className={styles.donationLabel}>
          Add a tip donation for our cause
        </div>
        <div className={styles.donationDescription}>
          Special Kids Fund is the 501c3 charity that administers this website
          and provides wheelchair vans to special needs kids, the elderly,
          adults with debilitating illnesses, and disabled veterans in need.
          Help us make a difference!
        </div>
        <div className={styles.amountLabel}>
          Enter any dollar amount or leave 0
        </div>
        <div className={styles.amountWrapper}>
          <input
            type="number"
            value={tipAmount}
            onChange={e => setTipAmount(Number(e.target.value))}
          />
          ${tipAmount}
        </div>
      </div>
      <div className={`col-9 ${styles.content}`}>
        <Alert color="danger" isOpen={!!error} toggle={() => setError()}>
          <div className={styles.errorWrapper}>{error}</div>
        </Alert>
        <div className={styles.title}>
          Would you like to add another set of gift cards to your order?
        </div>
        <Button
          className={styles.btnContinue}
          outline
          color="red"
          onClick={handleAddMore}>
          Yes, let’s add more
        </Button>
        <Button
          className={styles.btnContinue}
          loading={loading}
          onClick={handleContinue}>
          No thanks, view cart
        </Button>
      </div>
    </div>
  )
}

export default Step5
