import React from "react";
import { withRouter } from "react-router-dom";

export const ScrollToTop = withRouter(
  class ScrollToTopWithoutRouter extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
      }
    }
    render() {
      return this.props.children;
    }
  }
);
