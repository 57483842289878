import classnames from "classnames"
import React, { useEffect, useRef } from "react"
import Carousel from "react-multi-carousel"
import PhysicalCardIcon from "../../../images/physical-card-icon.svg"
import GiftCardCustomIcon from "../../../images/gift-card-custom.svg"
import DigitalCardIcon from "../../../images/digital-card-icon.svg"
import PrintCardIcon from "../../../images/print-card.svg"
import HonorCardIcon from "../../../images/honor-card-icon.svg"
import RedemptionCodesIcon from "../../../images/redemption-codes.svg"
import DonateIcon from "../../../images/donate.svg"
import DonateBalanceIcon from "../../../images/donate-balance.svg"
import styles from "./styles.module.scss"

const PurchaseItems = [
  {
    caption: "Physical Cards",
    icon: PhysicalCardIcon,
    iconAlt:
      "Buy the best charity gift cards for friends and clients. Holiday, Birthday, Seasons Greetings and Christmas Stocking Stuffers.",
    text: "Gift cards with one of our CharityChoice occasion designs; holiday, birthday, flower or charity ribbons.",
    url: "/Purchase/PhysicalCards",
    linkLabel: "Give Physical Cards",
  },
  {
    caption: "Digital Cards",
    icon: DigitalCardIcon,
    iconAlt:
      "Send your best last-minute eGifts as eCards, text messages or post directly to friend's platform - Facebook, Twitter etc.",
    text: "Select a card to share by email, text, or social media. Last-minute gift idea; send now or later.",
    url: "/Purchase/DigitalCards",
    linkLabel: "Give Digital Cards",
  },
  {
    caption: "Custom Gift Cards",
    icon: GiftCardCustomIcon,
    iconAlt:
      "Donate to purchase charity gift cards. Glossy Custom physical and Digital eGifts. Tax deductible, the best gifting idea.",
    text: "Corporate and Events: Customize bulk physical cards with your logo or your own, branded artwork.",
    url: "/Purchase/PhysicalCards?type=3",
    linkLabel: "Give Custom Cards",
  },
  {
    caption: "Print Your Own Cards",
    icon: PrintCardIcon,
    iconAlt: "Print Your Own Cards. Donate in lieu of holiday gifts this year.",
    text: "Receive a high-quality PDF card to print at home.",
    url: "/Purchase/PrintCards",
    linkLabel: "Give Printable Cards",
  },
  {
    caption: "Redemption Codes",
    icon: RedemptionCodesIcon,
    iconAlt:
      "CSV file of redemption codes for clients. Redeem gift card for top, highly rated charities. Best nonprofits - local, national and International.",
    text: "Buy unique codes to download and distribute using your own materials via any method you wish.",
    url: "/Purchase/RedemptionCodes",
    linkLabel: "Buy Redemption Codes",
  },
  {
    caption: "Honor Cards",
    icon: HonorCardIcon,
    iconAlt: "CharityChoice Honor Cards. Give to charity as my Holiday gift.",
    text: 'Make a donation to the charity of your choice "in honor of" your recipient.',
    url: "/Purchase/HonorCards",
    linkLabel: "Give Honor Cards",
  },
  {
    caption: "Donate Directly",
    icon: DonateIcon,
    iconAlt: "Donate to charity. Donate in lieu of Christmas gifts this year.",
    text: "Use your credit card to donate through us to a specific charity.",
    url: "/Purchase/DirectToCharityDonation",
    linkLabel: "Donate Directly",
  },
  {
    caption: "Donate Gift Card Balance",
    icon: DonateBalanceIcon,
    iconAlt:
      "Donate Gift Card Balance. Give to charity as my Christmas gift during COVID 19.",
    text: "Use your retail gift card balance to make a donation to a charity.",
    url: "/Purchase/DonateYourCard",
    linkLabel: "Donate Balance",
  },
]

export default function WaysToGive({ anchor }) {
  const giveGiftCards = useRef(null); //to be able to access the current one

  useEffect(() => {
    if (anchor && anchor.toLowerCase() === 'givegiftcards') {
      const element = giveGiftCards.current;
      //timeout is used to prevent jumping back up.      
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });

        //scrollIntoView sometimes doesn't work for Safari on a mobile
        const isMobileSafari = /(iPad|iPhone);.+Mobile/gi.test(navigator.userAgent);
        if (isMobileSafari) {
          let scrollTop = window.scrollY || element.scrollTop
          const finalOffset = (element.getBoundingClientRect().top + scrollTop) - 20
          window.parent.scrollTo({
            top: finalOffset,
            behavior: 'smooth'
          })
        }
      }, 1100);
    }
  }, [])

  const responsive = {
    largeDesktop: {
      breakpoint: { max: 4000, min: 1650 },
      items: 3,
    },
    mediumDesktop: {
      breakpoint: { max: 1650, min: 1080 },
      items: 3,
    },
    smallDesktop: {
      breakpoint: { max: 1080, min: 768 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 428 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
    },
  }
  const renderSinglePurchaseItem = ({
    caption,
    icon,
    iconAlt,
    text,
    url,
    linkLabel,
  }) => {
    return (
      <div key={caption}>
        <div className={styles.sliderItem}>
          <img className={styles.icon} src={icon} alt={iconAlt} />
          <div className={styles.itemDetail}>
            <div className={styles.caption}>{caption}</div>
            <div className={styles.text}>{text}</div>
            <a className={styles.link} href={url}>
              {linkLabel}
            </a>
          </div>
        </div>
      </div>
    )
  }

  const CustomArrows = props => {
    const { carouselState, next, previous } = props
    const { totalItems, slidesToShow, currentSlide } = carouselState
    const currentShowing = currentSlide + slidesToShow
    return (
      <>
        {!!currentSlide && (
          <button className={styles.btnArrow} onClick={previous}>
            <i className="fa-solid fa-chevron-left" />
          </button>
        )}
        <button
          className={classnames(styles.btnArrow, styles.right)}
          onClick={next}>
          {currentShowing < totalItems && (
            <i className="fa-solid fa-chevron-right" />
          )}
        </button>
      </>
    )
  }

  return (
    <div id="GiveGiftCards" className={styles.container} ref={giveGiftCards}>
      <a name="giveGiftCards"></a>
      <div style={{ flex: 1 }}>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlaySpeed={3000}
          centerMode={false}
          containerClass={styles.carouselContainer}
          customButtonGroup={<CustomArrows />}
          draggable
          focusOnSelect={false}
          itemClass={styles.carouselItem}
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside
          responsive={responsive}
          shouldResetAutoplay
          sliderClass={styles.carouselTrack}
          slidesToSlide={1}
          swipeable>
          {PurchaseItems.map(item => renderSinglePurchaseItem(item))}
        </Carousel>
      </div>
    </div>
  )
}
