import React, { useEffect, useState } from "react"
import { FormGroup, Input, Label } from "reactstrap"
import { Button, Loading } from "../../.."
import {
  GetCustomShippingTypes,
  GetPhysicalShippingTypes,
  PhysicalCardTypes,
} from "../../../../Code/Data"
import { usePurchaseData } from "../../../../Context/purchase"
import styles from "./styles.module.scss"

function Step4({ onContinue }) {
  const [loading, setLoading] = useState()
  const [shippingTypes, setShippingTypes] = useState([])
  const { physicalCardData, setPhysicalCardData } = usePurchaseData()
  const { deliveryMethod, shippingTypeId } = physicalCardData

  useEffect(() => {
    async function getShippingTypeList() {
      setLoading(true)
      if (deliveryMethod === PhysicalCardTypes.Custom) {
        const { List } = await GetCustomShippingTypes()
        setShippingTypes(List)
      } else {
        const { List } = await GetPhysicalShippingTypes()
        setShippingTypes(List)
      }
      setLoading(false)
    }

    getShippingTypeList()
  }, [])
  const handleChangeShippingType = e => {
    setPhysicalCardData({
      ...physicalCardData,
      shippingTypeId: Number(e.target.value),
    })
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>When would you like your cards?</div>
      <div className={styles.description}>
        Orders are shipped on the same business day only for orders made before
        noon Eastern Time. All other orders are shipped on the next business
        day.
      </div>

      <div className={styles.content}>
        <div className={`flex-column ${styles.shippingTypesInfo}`}>
          {loading && <Loading />}
          {!loading &&
            shippingTypes.filter(
              ({ IsHolidayRecommended }) => !IsHolidayRecommended,
            ) && (
              <FormGroup
                className={styles.selectorWrapper}
                onChange={handleChangeShippingType}>
                {shippingTypes
                  .filter(({ IsHolidayRecommended }) => !IsHolidayRecommended)
                  .map(item => (
                    <FormGroup key={item.ShippingTypeId} check>
                      <div className={styles.shippingOption}>

                        <Label check>
                          <Input
                            checked={item.ShippingTypeId === shippingTypeId}
                            type="radio"
                            name="radio1"
                            value={item.ShippingTypeId}
                          />{" "}
                          <b style={{ color: "var(--red)" }}> $ {item.BaseShippingCost} </b>

                          {item.ShippingCompanyName.split(" ")[0]} -
                          <b>  {item.ShippingTypeDescription}</b>
                          <br />
                          <small dangerouslySetInnerHTML={{ __html: item.ShippingTypeNotes }}></small>
                        </Label>
                      </div>
                    </FormGroup>
                  ))}
              </FormGroup>
            )}
          {!loading &&
            shippingTypes.filter(
              ({ IsHolidayRecommended }) => IsHolidayRecommended,
            ) && (
              <FormGroup
                className={styles.selectorWrapper}
                onChange={handleChangeShippingType}>
                {shippingTypes
                  .filter(({ IsHolidayRecommended }) => IsHolidayRecommended)
                  .map(item => (
                    <FormGroup key={item.ShippingTypeId} check>
                      <div className={styles.shippingOption}>
                        <Label check>
                          <Input
                            checked={item.ShippingTypeId === shippingTypeId}
                            type="radio"
                            name="radio1"
                            value={item.ShippingTypeId}
                          />{" "}
                          <b style={{ color: "var(--red)" }}> $ {item.BaseShippingCost} </b>

                          {item.ShippingCompanyName.split(" ")[0]} -
                          <b> {item.ShippingTypeDescription}</b>
                          <br />
                          <small>{item.ShippingTypeNotes} </small>
                        </Label></div>
                    </FormGroup>
                  ))}
              </FormGroup>
            )}
        </div>
        {!loading && (
          <div className="flex-column">
            <div className={styles.deliveryInfo}><i className="fa-solid fa-calendar-check fa-5x"></i>
              <div className={styles.deliveryDate}>
                {shippingTypes.find(shippingType => shippingType.ShippingTypeId === physicalCardData.shippingTypeId)?.ExpectedArrivalTime}
              </div>
              <small> Your estimated delivery date</small></div>
            <Button
              className={styles.btnContinue}
              disabled={!shippingTypeId}
              onClick={onContinue}>
              Continue
            </Button>
          </div>
        ) }
      </div>
    </div>
  )
}

export default Step4
