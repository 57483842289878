import React, { useState } from "react"
import { Popover, PopoverHeader, PopoverBody } from "reactstrap"
import QRCodeBanner from "../../../images/QRCodeBanner.png"
import Button from "../../Shared/Button"
import styles from "./styles.module.scss"

export default function HelpBanner() {
  const [popover, setPopover] = useState(false)
  const togglePopover = () => setPopover(popover ? false : true)
  return (
    <div className={styles.container} id="buttonPopover">
      <div className={styles.content}>
        <img src={QRCodeBanner} className={styles.qrCodeBanner}
             alt="Multi-redeemable charity promotion QR codes for cause-related (CRM) swag and events." />
        <Button color="red" id="buttonPopover" onClick={togglePopover}>Learn More</Button>
        <Popover
          placement="bottom"
          trigger="legacy"
          isOpen={popover}
          target="buttonPopover"
          toggle={togglePopover}
          onClick={togglePopover}
          offset={[600, -55]}>
          <PopoverHeader>
            <div>
              Add a charitable dimension to your event!
              <br />
              Multi- redeemable QR code.
              <br />
              <br />
              <a href="/Purchase/Favors-Swag-QR-Codes"><Button size="very-small">Order Now</Button></a>
            </div>
          </PopoverHeader>
          <PopoverBody>
            <p>
              Add a charitable dimension to your event! Multi- redeemable QR codes... print the same one on each item.
            </p>
            <p>
              Show the code's value or keep discrete.
            </p>
            <ul>
              <li>Business</li>
            </ul>
            <p>
              Swag and Party Favors for your event, shows, getaways, and holiday office parties.
            </p>
            <ul>
              <li>Personal</li>
            </ul>
            <p>
              Favors for parties and get-togethers. Any event for special occasions; birthdays, anniversaries, and
              holidays.
              <br />
              Wedding invitations and Bridal favors.
              <br />
              Graduations, sports events and outings.
            </p>
            <p>
              Share your passion for causes with your family and friends.
              <br />
              Add a charitable dimension to your event! DEI, animals, hunger, disaster relief, veterans or whatever
              cause you support...
            </p>
          </PopoverBody>
        </Popover>
      </div>
    </div>
  )
}
