import React from "react"
import styles from "./styles.module.scss"

export default function CharityBanner() {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        2,500+ <br /><span>Charities to choose from</span>
      </div>
      <div className={styles.right}>
        <div className={styles.title}>
          A gift with a purpose…
        </div>
        <div className={styles.description}>
          Our charity gift cards feature a wide selection of beautiful designs and are the perfect gift - <b>a gift with a purpose</b>. Giving back for birthdays, holidays, sympathy, thank yous, or any special occasion. And with more than 2500 charities to choose from, everyone can find a meaningful cause close to their heart.<br />
          <span><b>More purpose, less stuff...</b></span>
        </div>
        <div className={styles.bottomTitle} title="CharityChoice is a fundraising project of Special Kids Fund, benefiting underprivileged children with disabilities. Through CharityChoice we enable donors to help a broad range of social service causes through their tax deductible charity gifts..">
          100% Charitable giving!
        </div>
      </div>
    </div>
  )
}
